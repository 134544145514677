import React, {useState} from "react";
import {Link} from 'react-router-dom';
import { gamesdata } from '../components/cheats-data'
import { cheats } from '../components/cheats-data'
import { motion } from 'framer-motion'
import rustactiveicon from '../images/logos/gamelogos/rustactive.webp'
import apexactiveicon from '../images/logos/gamelogos/apexactive.webp'
import tarkovactiveicon from '../images/logos/gamelogos/tarkovactive.webp'
import armaactiveicon from '../images/logos/gamelogos/armaactive.webp'
import scumactiveicon from '../images/logos/gamelogos/scum active.webp'
import dayzactiveicon from '../images/logos/gamelogos/dayzactive.webp'
import seaactiveicon from '../images/logos/gamelogos/seaactive.webp'
import waractiveicon from '../images/logos/gamelogos/warthunderactive.webp'
import fortniteactiveicon from '../images/logos/gamelogos/fortniteactive.webp'
import daysactiveicon from '../images/logos/gamelogos/dayzactive.webp'
import finalsactiveicon from '../images/logos/gamelogos/finalsactive.webp'
import arkactiveicon from '../images/logos/gamelogos/arkactive.webp'
import deadactiveicon from '../images/logos/gamelogos/deadactive.webp'
import cs2activeicon from '../images/logos/gamelogos/cs2active.webp'
import undefined from '../images/logos/gamelogos/undefined.webp'


export const Status = () => {


    const [activeStatuses, setActiveStatuses] = useState([]);
    const [searchItem, setSearchItem] = useState('')
    const [activeDropdowns, setActiveDropdowns] = useState([]);
    const [searchDropdown, setSearchDropdown] = useState('')

    const [open, setOpen] = useState(false)

    const toggleStatus = (index) => {
        if (!activeStatuses.includes(index)) {
            setActiveStatuses([...activeStatuses, index])
        } else {
            setActiveStatuses(activeStatuses.filter((status) => status !== index));
        }
    }
    const handleInputChange = (e) => { 
    
        const searchItem = e.target.value;
    
        setSearchItem(searchItem);
    
    }
    
    const handleDropdownInputChange = (e) => { 
    
        const searchDropdown = e.target.value;
    
        setSearchDropdown(searchDropdown);
    
    }

    const checkDropdownSearch = (game) => {
        if (searchDropdown.length > 0) {
          return game.toLowerCase().includes(searchDropdown.toLowerCase());
        } else {
          return true;
        }
    }

    const sortByLatestUpdated = (cheats) => {
        return cheats.slice().sort((a, b) => a.day - b.day);
      };
    
    const sortByLongestUndetected = (cheats) => {
        return cheats.slice().sort((a, b) => a.day - b.day);
    };
    
    const sortRiskPerecentage = (cheats) => {
        return cheats.slice();
    }
    


    const checkSearch = (cheat) => {
        if (searchItem.length > 0) {
          return cheat.name.toLowerCase().includes(searchItem.toLowerCase());
        } else {
          return true;
        }
    }

    const checkDropdowns = (cheat) => {
        if(activeDropdowns.length > 0) {
            return activeDropdowns.includes(cheat.game);
        } else {
            return true;
        }
    }

    const cheatMatchesFilter = (cheat) => {

        return checkSearch(cheat) && checkDropdowns(cheat);
    
      }

    const hasCheats = (game) => {
        return cheats.some(cheat => cheat.game === game && cheatMatchesFilter(cheat))
    }

    const handleDropdown = (dropdown) => {
        if (!activeDropdowns.includes(dropdown)) {
            setActiveDropdowns([...activeDropdowns, dropdown])
        } else {
            setActiveDropdowns(activeDropdowns.filter((item) => item !== dropdown));
        }
    }

    const getImageSource = (game) => {
        if (game === 'Rust') {
            return rustactiveicon;
        } else if (game === 'Apex Legends') {
            return apexactiveicon;
        } else if (game === 'Arma 3') {
            return armaactiveicon;
        } else if (game === 'Ark: Survival Ascended') {
            return arkactiveicon;
        } else if (game === 'Dead By Daylight') {
            return deadactiveicon;
        } else if (game === 'Escape from Tarkov') {
            return tarkovactiveicon;
        } else if (game === 'Scum') {
            return scumactiveicon;
        } else if (game === 'Dayz') {
            return dayzactiveicon;
        } else if (game === 'Sea of Thieves') {
            return seaactiveicon;
        } else if (game === 'War Thunder') {
            return waractiveicon;
        } else if (game === 'Fortnite') {
            return fortniteactiveicon;
        } else if (game === '7 Days To Die') {
            return  daysactiveicon;
        } else if (game === 'The Finals') {
            return finalsactiveicon;
        } else if (game === 'Ark: Survival Ascended') {
            return arkactiveicon;
        } else if (game === 'Dead By Daylight') {
            return deadactiveicon;
        } else if (game === 'Counter Strike 2') {
            return cs2activeicon;
        } else {
            return undefined;
        }
    }
        

    return (
        <motion.main className='status-main'
        initial={{width: 0, opacity: 0}}
        animate={{width: "100%", opacity: 1}}
        exit={{ x: window.innerWidth, opacity: 0, transition: {duration: 0.1}}}
        >
        <header className='status-header'>Status</header>
            <div className='status-filter'>
                <div className="status-searchbar">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m19.6 21l-6.3-6.3q-.75.6-1.725.95T9.5 16q-2.725 0-4.612-1.888T3 9.5q0-2.725 1.888-4.612T9.5 3q2.725 0 4.613 1.888T16 9.5q0 1.1-.35 2.075T14.7 13.3l6.3 6.3zM9.5 14q1.875 0 3.188-1.312T14 9.5q0-1.875-1.312-3.187T9.5 5Q7.625 5 6.313 6.313T5 9.5q0 1.875 1.313 3.188T9.5 14"/></svg>
                <input type="text" value={searchItem} onChange={handleInputChange} placeholder='Search Cheats' />
                </div>
                <div className='status-filter-dropdown'>
                    <div onClick={() => setOpen(!open)} className='dropdown-text'>
                    <p>Filter Games</p>
                    <svg className={open ? "rotated" : "normal"} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="#dfe4e4" d="M12 14.975q-.2 0-.375-.062T11.3 14.7l-4.6-4.6q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l3.9 3.9l3.9-3.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-4.6 4.6q-.15.15-.325.213t-.375.062"/></svg>
                    </div>
                </div>
            </div>

            <section className={open ? "filter-dropdown-menu-container filter-dropdown-menu-container-expand" : "filter-dropdown-menu-container"}>
                <div className={open ? "status-filter-dropdown-menu status-filter-dropdown-menu-open" : "status-filter-dropdown-menu"}>
                        <div className='filter-dropdown-searchbar'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m19.6 21l-6.3-6.3q-.75.6-1.725.95T9.5 16q-2.725 0-4.612-1.888T3 9.5q0-2.725 1.888-4.612T9.5 3q2.725 0 4.613 1.888T16 9.5q0 1.1-.35 2.075T14.7 13.3l6.3 6.3zM9.5 14q1.875 0 3.188-1.312T14 9.5q0-1.875-1.312-3.187T9.5 5Q7.625 5 6.313 6.313T5 9.5q0 1.875 1.313 3.188T9.5 14"/></svg>
                            <input type="text" value={searchDropdown} onChange={handleDropdownInputChange} placeholder='Search Games' />
                        </div>

                        <div className='filter-dropdown-games'>

                        {gamesdata.map((game) => {
                            return (
                            checkDropdownSearch(game) ? (
                            <div onClick={() => handleDropdown(game)}  className={activeDropdowns.includes(game) ? "dropdown-item-selected dropdown-item" : "dropdown-item"}>
                                <p>{game}</p>
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24"><path fill="currentColor" d="m8.401 16.333l-.734-.727l3.6-3.606l-3.6-3.58l.734-.728l3.6 3.596l3.573-3.596l.734.727l-3.6 3.58l3.6 3.607l-.734.727L12 12.737z"/></svg>
                            </div>
                            ) : (<></>)
                            )
                        })}
                        
                        </div>
                    </div>
                </section>


            {gamesdata.map((game) => {
                
                return (
                hasCheats(game) ? (
                    <div className='status-game'>
                    <h1><img key={game} src={getImageSource(game)} alt={`${game} logo`}/>{game}</h1>
                        <div className='status-cheats'>
                            {cheats.map((cheat, index) => {
                                return (
                                    cheat.game === game && cheatMatchesFilter(cheat) ? (
                                        <div onClick={() => toggleStatus(index)} className={activeStatuses.includes(index) ? "cheat-status cheat-status-active" : "cheat-status"}>
                                            <div className='cheat-status-top'>
                                            <Link aria-label={`Link to ${game} ${cheat.name}`} to={`/games/${game}/${cheat.name}`}>
                                            <h2 className='cheat-status-name'>{cheat.name}<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="currentColor" d="M3.75 2h3.5a.75.75 0 0 1 0 1.5h-3.5a.25.25 0 0 0-.25.25v8.5c0 .138.112.25.25.25h8.5a.25.25 0 0 0 .25-.25v-3.5a.75.75 0 0 1 1.5 0v3.5A1.75 1.75 0 0 1 12.25 14h-8.5A1.75 1.75 0 0 1 2 12.25v-8.5C2 2.784 2.784 2 3.75 2m6.854-1h4.146a.25.25 0 0 1 .25.25v4.146a.25.25 0 0 1-.427.177L13.03 4.03L9.28 7.78a.751.751 0 0 1-1.042-.018a.751.751 0 0 1-.018-1.042l3.75-3.75l-1.543-1.543A.25.25 0 0 1 10.604 1"/></svg></h2>
                                            </Link>
                                            <div className='status'>
                                                <p className={cheat.isUndetected ? "undetected-status" : cheat.isUpdating ? "updating-status" : "risky-status"}>{cheat.isUndetected ? "Undetected" : cheat.isUpdating ? "Updating" : "Use at Own Risk"}</p>
                                                <svg className={activeStatuses.includes(index) ? "rotated" : "normal"} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="#dfe4e4" d="M12 14.975q-.2 0-.375-.062T11.3 14.7l-4.6-4.6q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l3.9 3.9l3.9-3.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-4.6 4.6q-.15.15-.325.213t-.375.062"/></svg>
                                            </div>
                                            </div>
                                            <div className='cheat-status-info'>
                                                <p>Last Updated: DD/MM/YY</p>
                                                <p>Risk Percentage: 0%</p>
                                                <p>Undetected since: DD/MM/YY</p>
                                            </div>
                                        </div>
                                        ) : <></>
                                )
                            })}
                            
                        </div>
                    </div>
                ) : <></>
                ) 
            })}

        </motion.main>
    );
}