import React, {useState} from "react";

import { motion } from 'framer-motion'

export const Policy = () => {

    const [policy, setPolicy] = useState(1)

    return (
        <motion.main className='policy-main'
        initial={{width: 0, opacity: 0}}
        animate={{width: "100%", opacity: 1}}
        exit={{ x: window.innerWidth, opacity: 0, transition: {duration: 0.1}}}
        >
            <header className='policy-header'>Privacy Policy</header>
            <div className='policy-nav'>
                <div onClick={() => setPolicy(1)} className={policy === 1 ? "policy-nav-item policy-nav-item-active" : "policy-nav-item"}>
                    <p>TOS</p>
                </div>

                <div className='policy-line'></div>

                <div onClick={() => setPolicy(2)} className={policy === 2 ? "policy-nav-item policy-nav-item-active" : "policy-nav-item"}>
                    <p>PRIVACY</p>
                </div>

                <div onClick={() => setPolicy(3)} className={policy === 3 ? "policy-nav-item policy-nav-item-active" : "policy-nav-item"}>
                    <p>OTHER</p>
                </div>
            </div>

            <div className={policy === 1 ? "policy active" : "policy"}>
                <header>1. TOS TITLE</header>
                <p>text here text here text here text here text here</p>
                <ul>
                    <li>LIST HERE</li>
                    <li>LIST HERE</li>
                    <li>LIST HERE</li>
                </ul>
            </div>

            <div className={policy === 2 ? "policy active" : "policy"}>
                <header>1. PRIVACY TITLE</header>
                <p>text here text here text here text here text here</p>
            </div>

            <div className={policy === 3 ? "policy active" : "policy"}>
                <header>1. OTHER TITLE</header>
                <p>text here text here text here text here text here</p>
            </div>
        </motion.main>
    );
}