import React, { useRef, useState } from "react";
import { BrowserRouter as Router, Routes, Link, Route} from 'react-router-dom';

import hood from '../images/logos/cheatlogos/hood.webp'
import { gamesdata } from "./cheats-data";
import { cheats } from "../components/cheats-data";

export const Footer = () => {

    return (
       <footer>
          <nav className="footer-links">
          <div className="footer-products footer-mobile">
                  <ul>
                    <li className="footer-nav-title">SOCIAL</li>
                    <li><Link aria-label="Link to our cheating blogs" to="/blogs">Blogs</Link></li>
                    <li><Link aria-label="Link to our cheating forums" to="/Forums">Forums</Link></li>
                    <li><Link aria-label="Link to our support page" to="/Support">Support</Link></li>
                    <li><Link aria-label="Join our Discord" to="/Discord">Discord</Link></li>
                  </ul>
                  <ul>
                    <li className="footer-nav-title">NAVIGATION</li>
                    <li><Link aria-label="Link to our home page" to="/">Home</Link></li>
                    <li><Link aria-label="Link to our store page" to="/games">Store</Link></li>
                    <li><Link aria-label="Link to the status of our cheats" to="/status">Status</Link></li>
                    <li><Link aria-label="Link to our cheating blogs" to="/blogs">Blogs</Link></li>
                    <li><Link aria-label="Link to our cheating forums" to="/forums">Forums</Link></li>
                    <li><Link aria-label="Link to our support page" to="/support">Support</Link></li>
                  </ul>
                  <ul>
                    <li className="footer-nav-title">CONTACT</li>
                    <li><Link to="gmail">contact@gmail.com</Link></li>
                  </ul>
              </div>
              <div className="footer-divide footer-mobile"></div>
                  <div className="footer-nav">
                    <div className="footer-hood">
                      <h1><img alt="Hoodcheats logo" src={hood} />HOODCHEATS</h1>
                      <p>Hoodcheats strives to create high quality professional cheats for your favorite games.</p>
                      <div className = "footer-contacts">
                      <Link aria-label="Join our Discord" to="/"><svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24"><path fill="#dfe4e4" d="M19.27 5.33C17.94 4.71 16.5 4.26 15 4a.09.09 0 0 0-.07.03c-.18.33-.39.76-.53 1.09a16.09 16.09 0 0 0-4.8 0c-.14-.34-.35-.76-.54-1.09c-.01-.02-.04-.03-.07-.03c-1.5.26-2.93.71-4.27 1.33c-.01 0-.02.01-.03.02c-2.72 4.07-3.47 8.03-3.1 11.95c0 .02.01.04.03.05c1.8 1.32 3.53 2.12 5.24 2.65c.03.01.06 0 .07-.02c.4-.55.76-1.13 1.07-1.74c.02-.04 0-.08-.04-.09c-.57-.22-1.11-.48-1.64-.78c-.04-.02-.04-.08-.01-.11c.11-.08.22-.17.33-.25c.02-.02.05-.02.07-.01c3.44 1.57 7.15 1.57 10.55 0c.02-.01.05-.01.07.01c.11.09.22.17.33.26c.04.03.04.09-.01.11c-.52.31-1.07.56-1.64.78c-.04.01-.05.06-.04.09c.32.61.68 1.19 1.07 1.74c.03.01.06.02.09.01c1.72-.53 3.45-1.33 5.25-2.65c.02-.01.03-.03.03-.05c.44-4.53-.73-8.46-3.1-11.95c-.01-.01-.02-.02-.04-.02zM8.52 14.91c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.84 2.12-1.89 2.12zm6.97 0c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.83 2.12-1.89 2.12z"/></svg></Link>
                      <Link aria-label="Join our Telegram" to="/"><svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24"><path d="M9.78 18.65l.28-4.23l7.68-6.92c.34-.31-.07-.46-.52-.19L7.74 13.3L3.64 12c-.88-.25-.89-.86.2-1.3l15.97-6.16c.73-.33 1.43.18 1.15 1.3l-2.72 12.81c-.19.91-.74 1.13-1.5.71L12.6 16.3l-1.99 1.93c-.23.23-.42.42-.83.42z" fill="#dfe4e4"/></svg></Link>
                      <Link aria-label="Join our Tiktok" to="/"><svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24"><path fill="#dfe4e4" d="M16.6 5.82s.51.5 0 0A4.278 4.278 0 0 1 15.54 3h-3.09v12.4a2.592 2.592 0 0 1-2.59 2.5c-1.42 0-2.6-1.16-2.6-2.6c0-1.72 1.66-3.01 3.37-2.48V9.66c-3.45-.46-6.47 2.22-6.47 5.64c0 3.33 2.76 5.7 5.69 5.7c3.14 0 5.69-2.55 5.69-5.7V9.01a7.35 7.35 0 0 0 4.3 1.38V7.3s-1.88.09-3.24-1.48z"/></svg></Link>
                      <Link aria-label="Join our Youtube" to="/"><svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24"><path fill="#dfe4e4" d="m10 15l5.19-3L10 9v6m11.56-7.83c.13.47.22 1.1.28 1.9c.07.8.1 1.49.1 2.09L22 12c0 2.19-.16 3.8-.44 4.83c-.25.9-.83 1.48-1.73 1.73c-.47.13-1.33.22-2.65.28c-1.3.07-2.49.1-3.59.1L12 19c-4.19 0-6.8-.16-7.83-.44c-.9-.25-1.48-.83-1.73-1.73c-.13-.47-.22-1.1-.28-1.9c-.07-.8-.1-1.49-.1-2.09L2 12c0-2.19.16-3.8.44-4.83c.25-.9.83-1.48 1.73-1.73c.47-.13 1.33-.22 2.65-.28c1.3-.07 2.49-.1 3.59-.1L12 5c4.19 0 6.8.16 7.83.44c.9.25 1.48.83 1.73 1.73Z"/></svg></Link>
                    </div>
                  </div>
                  <ul>
                    <li className="footer-nav-title">SOCIAL</li>
                    <li><Link aria-label="Link to our cheating blogs" to="/blogs">Blogs</Link></li>
                    <li><Link aria-label="Link to our cheating forums" to="/forums">Forums</Link></li>
                    <li><Link aria-label="Link to our support page" to="/support">Support</Link></li>
                    <li><Link aria-label="Join our discord" to="/Discord">Discord</Link></li>
                  </ul>
                  <ul>
                    <li className="footer-nav-title">NAVIGATION</li>
                    <li><Link aria-label="Link to our home page" to="/">Home</Link></li>
                    <li><Link aria-label="Link to our store page" to="/games">Store</Link></li>
                    <li><Link aria-label="Link to the status of our cheats" to="/status">Status</Link></li>
                    <li><Link aria-label="Link to our cheating blogs" to="/blogs">Blogs</Link></li>
                    <li><Link aria-label="Link to our cheating forums" to="/forums">Forums</Link></li>
                    <li><Link aria-label="Link to our support page" to="/support">Support</Link></li>
                  </ul>
                  <ul>
                    <li className="footer-nav-title">CONTACT</li>
                    <li><Link to="gmail">contact@gmail.com</Link></li>
                  </ul>
              </div>
              <div className="footer-divide"></div>
              <div className="footer-products">
              {gamesdata.map((game) => {
                return (
                  <ul>
                  <li className="footer-nav-title">{game}</li>
                  {cheats.map((cheat) => {
                    return (
                      cheat.game === game ? (
                        <li><Link to="/">{cheat.name}</Link></li>
                      ) : (<></>)
                    )
                  })}
                </ul>
                );
              })}
              </div>

          <div className="footer-divide"></div>
          
          <div className="footer-sublinks">
            <div className="sublinks">
              <p><Link aria-label="Link to our about page" to="/About">About Us</Link></p>
              <p><Link aria-label="Link to our policy page" to="/Policy">Privacy Policy</Link></p>
              <p><Link aria-label="Link to our cheating blogs" to="/blogs">Blogs</Link></p>
              <p><Link aria-label="Link to our cheating forums" to="/forums">Forums</Link></p>
              <p><Link aria-label="Link to our sitemap" to="/Sitemap">Sitemap</Link></p>
              <p><Link aria-label="Link to our support page" to="/support">Support</Link></p>
              <p><Link aria-label="Contact us" to="/Contact">Contact Us</Link></p>
            </div>
            <div className="payments">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M9.93 12.99c.1 0 2.42.1 3.8-.24h.01c1.59-.39 3.8-1.51 4.37-5.17c0 0 1.27-4.58-5.03-4.58H7.67c-.49 0-.91.36-.99.84L4.38 18.4c-.05.3.19.58.49.58H8.3l.84-5.32c.06-.38.39-.67.79-.67"/><path fill="currentColor" d="M18.99 8.29c-.81 3.73-3.36 5.7-7.42 5.7H10.1l-1.03 6.52c-.04.26.16.49.42.49h1.9c.34 0 .64-.25.69-.59c.08-.4.52-3.32.61-3.82c.05-.34.35-.59.69-.59h.44c2.82 0 5.03-1.15 5.68-4.46c.26-1.34.12-2.44-.51-3.25"/></svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="32" viewBox="0 0 640 512"><path fill="currentColor" d="m165 144.7l-43.3 9.2l-.2 142.4c0 26.3 19.8 43.3 46.1 43.3c14.6 0 25.3-2.7 31.2-5.9v-33.8c-5.7 2.3-33.7 10.5-33.7-15.7V221h33.7v-37.8h-33.7zm89.1 51.6l-2.7-13.1H213v153.2h44.3V233.3c10.5-13.8 28.2-11.1 33.9-9.3v-40.8c-6-2.1-26.7-6-37.1 13.1m92.3-72.3l-44.6 9.5v36.2l44.6-9.5zM44.9 228.3c0-6.9 5.8-9.6 15.1-9.7c13.5 0 30.7 4.1 44.2 11.4v-41.8c-14.7-5.8-29.4-8.1-44.1-8.1c-36 0-60 18.8-60 50.2c0 49.2 67.5 41.2 67.5 62.4c0 8.2-7.1 10.9-17 10.9c-14.7 0-33.7-6.1-48.6-14.2v40c16.5 7.1 33.2 10.1 48.5 10.1c36.9 0 62.3-15.8 62.3-47.8c0-52.9-67.9-43.4-67.9-63.4M640 261.6c0-45.5-22-81.4-64.2-81.4s-67.9 35.9-67.9 81.1c0 53.5 30.3 78.2 73.5 78.2c21.2 0 37.1-4.8 49.2-11.5v-33.4c-12.1 6.1-26 9.8-43.6 9.8c-17.3 0-32.5-6.1-34.5-26.9h86.9c.2-2.3.6-11.6.6-15.9m-87.9-16.8c0-20 12.3-28.4 23.4-28.4c10.9 0 22.5 8.4 22.5 28.4zm-112.9-64.6c-17.4 0-28.6 8.2-34.8 13.9l-2.3-11H363v204.8l44.4-9.4l.1-50.2c6.4 4.7 15.9 11.2 31.4 11.2c31.8 0 60.8-23.2 60.8-79.6c.1-51.6-29.3-79.7-60.5-79.7m-10.6 122.5c-10.4 0-16.6-3.8-20.9-8.4l-.3-66c4.6-5.1 11-8.8 21.2-8.8c16.2 0 27.4 18.2 27.4 41.4c.1 23.9-10.9 41.8-27.4 41.8m-126.7 33.7h44.6V183.2h-44.6z"/></svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M17 6H3v8h14zm-7 7c-1.66 0-3-1.34-3-3s1.34-3 3-3s3 1.34 3 3s-1.34 3-3 3" opacity=".3"/><path fill="currentColor" d="M17 4H3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2M3 14V6h14v8z"/><path fill="currentColor" d="M10 7c-1.66 0-3 1.34-3 3s1.34 3 3 3s3-1.34 3-3s-1.34-3-3-3m13 0v11c0 1.1-.9 2-2 2H4v-2h17V7z"/></svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="currentColor" fill-rule="evenodd" d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16s-7.163 16-16 16m7.189-17.98c.314-2.096-1.283-3.223-3.465-3.975l.708-2.84l-1.728-.43l-.69 2.765c-.454-.114-.92-.22-1.385-.326l.695-2.783L15.596 6l-.708 2.839c-.376-.086-.746-.17-1.104-.26l.002-.009l-2.384-.595l-.46 1.846s1.283.294 1.256.312c.7.175.826.638.805 1.006l-.806 3.235c.048.012.11.03.18.057l-.183-.045l-1.13 4.532c-.086.212-.303.531-.793.41c.018.025-1.256-.313-1.256-.313l-.858 1.978l2.25.561c.418.105.828.215 1.231.318l-.715 2.872l1.727.43l.708-2.84c.472.127.93.245 1.378.357l-.706 2.828l1.728.43l.715-2.866c2.948.558 5.164.333 6.097-2.333c.752-2.146-.037-3.385-1.588-4.192c1.13-.26 1.98-1.003 2.207-2.538m-3.95 5.538c-.533 2.147-4.148.986-5.32.695l.95-3.805c1.172.293 4.929.872 4.37 3.11m.535-5.569c-.487 1.953-3.495.96-4.47.717l.86-3.45c.975.243 4.118.696 3.61 2.733"/></svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><g fill="currentColor" fill-rule="evenodd"><path d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16s-7.163 16-16 16m7.994-15.781L16.498 4L9 16.22l7.498 4.353zM24 17.616l-7.502 4.351L9 17.617l7.498 10.378z"/><g fill-rule="nonzero"><path fill-opacity=".298" d="M16.498 4v8.87l7.497 3.35zm0 17.968v6.027L24 17.616z"/><path fill-opacity=".801" d="m16.498 20.573l7.497-4.353l-7.497-3.348z"/><path fill-opacity=".298" d="m9 16.22l7.498 4.353v-7.701z"/></g></g></svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="currentColor" fill-rule="evenodd" d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16s-7.163 16-16 16m1.922-18.207v-2.366h5.414V7.819H8.595v3.608h5.414v2.365c-4.4.202-7.709 1.074-7.709 2.118c0 1.044 3.309 1.915 7.709 2.118v7.582h3.913v-7.584c4.393-.202 7.694-1.073 7.694-2.116c0-1.043-3.301-1.914-7.694-2.117m0 3.59v-.002c-.11.008-.677.042-1.942.042c-1.01 0-1.721-.03-1.971-.042v.003c-3.888-.171-6.79-.848-6.79-1.658c0-.809 2.902-1.486 6.79-1.66v2.644c.254.018.982.061 1.988.061c1.207 0 1.812-.05 1.925-.06v-2.643c3.88.173 6.775.85 6.775 1.658c0 .81-2.895 1.485-6.775 1.657"/></svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M11 13H5v-2h6V5h2v6h6v2h-6v6h-2z"/></svg>
            
            </div>
          </div>

          <div className="footer-sublinks-mobile">
            <div className="sublinks">
              <p><Link aria-label="Link to our about page" to="/About">About Us</Link></p>
              <p><Link aria-label="Link to our policy page" to="/Policy">Privacy Policy</Link></p>
              <p><Link aria-label="Link to our cheating blogs" to="/blogs">Blogs</Link></p>
              <p><Link aria-label="Link to our cheating forums" to="/forums">Forums</Link></p>
              <p><Link aria-label="Link to our sitemap" to="/Sitemap">Sitemap</Link></p>
              <p><Link aria-label="Link to our support page" to="/support">Support</Link></p>
              <p><Link aria-label="Contact us" to="/Contact">Contact Us</Link></p>
            </div>
          </div>
          <div className="footer-divide-mobile"></div>

          <div className="footer-sublinks-mobile">
          <div className="payments">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M9.93 12.99c.1 0 2.42.1 3.8-.24h.01c1.59-.39 3.8-1.51 4.37-5.17c0 0 1.27-4.58-5.03-4.58H7.67c-.49 0-.91.36-.99.84L4.38 18.4c-.05.3.19.58.49.58H8.3l.84-5.32c.06-.38.39-.67.79-.67"/><path fill="currentColor" d="M18.99 8.29c-.81 3.73-3.36 5.7-7.42 5.7H10.1l-1.03 6.52c-.04.26.16.49.42.49h1.9c.34 0 .64-.25.69-.59c.08-.4.52-3.32.61-3.82c.05-.34.35-.59.69-.59h.44c2.82 0 5.03-1.15 5.68-4.46c.26-1.34.12-2.44-.51-3.25"/></svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="32" viewBox="0 0 640 512"><path fill="currentColor" d="m165 144.7l-43.3 9.2l-.2 142.4c0 26.3 19.8 43.3 46.1 43.3c14.6 0 25.3-2.7 31.2-5.9v-33.8c-5.7 2.3-33.7 10.5-33.7-15.7V221h33.7v-37.8h-33.7zm89.1 51.6l-2.7-13.1H213v153.2h44.3V233.3c10.5-13.8 28.2-11.1 33.9-9.3v-40.8c-6-2.1-26.7-6-37.1 13.1m92.3-72.3l-44.6 9.5v36.2l44.6-9.5zM44.9 228.3c0-6.9 5.8-9.6 15.1-9.7c13.5 0 30.7 4.1 44.2 11.4v-41.8c-14.7-5.8-29.4-8.1-44.1-8.1c-36 0-60 18.8-60 50.2c0 49.2 67.5 41.2 67.5 62.4c0 8.2-7.1 10.9-17 10.9c-14.7 0-33.7-6.1-48.6-14.2v40c16.5 7.1 33.2 10.1 48.5 10.1c36.9 0 62.3-15.8 62.3-47.8c0-52.9-67.9-43.4-67.9-63.4M640 261.6c0-45.5-22-81.4-64.2-81.4s-67.9 35.9-67.9 81.1c0 53.5 30.3 78.2 73.5 78.2c21.2 0 37.1-4.8 49.2-11.5v-33.4c-12.1 6.1-26 9.8-43.6 9.8c-17.3 0-32.5-6.1-34.5-26.9h86.9c.2-2.3.6-11.6.6-15.9m-87.9-16.8c0-20 12.3-28.4 23.4-28.4c10.9 0 22.5 8.4 22.5 28.4zm-112.9-64.6c-17.4 0-28.6 8.2-34.8 13.9l-2.3-11H363v204.8l44.4-9.4l.1-50.2c6.4 4.7 15.9 11.2 31.4 11.2c31.8 0 60.8-23.2 60.8-79.6c.1-51.6-29.3-79.7-60.5-79.7m-10.6 122.5c-10.4 0-16.6-3.8-20.9-8.4l-.3-66c4.6-5.1 11-8.8 21.2-8.8c16.2 0 27.4 18.2 27.4 41.4c.1 23.9-10.9 41.8-27.4 41.8m-126.7 33.7h44.6V183.2h-44.6z"/></svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M17 6H3v8h14zm-7 7c-1.66 0-3-1.34-3-3s1.34-3 3-3s3 1.34 3 3s-1.34 3-3 3" opacity=".3"/><path fill="currentColor" d="M17 4H3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2M3 14V6h14v8z"/><path fill="currentColor" d="M10 7c-1.66 0-3 1.34-3 3s1.34 3 3 3s3-1.34 3-3s-1.34-3-3-3m13 0v11c0 1.1-.9 2-2 2H4v-2h17V7z"/></svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="currentColor" fill-rule="evenodd" d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16s-7.163 16-16 16m7.189-17.98c.314-2.096-1.283-3.223-3.465-3.975l.708-2.84l-1.728-.43l-.69 2.765c-.454-.114-.92-.22-1.385-.326l.695-2.783L15.596 6l-.708 2.839c-.376-.086-.746-.17-1.104-.26l.002-.009l-2.384-.595l-.46 1.846s1.283.294 1.256.312c.7.175.826.638.805 1.006l-.806 3.235c.048.012.11.03.18.057l-.183-.045l-1.13 4.532c-.086.212-.303.531-.793.41c.018.025-1.256-.313-1.256-.313l-.858 1.978l2.25.561c.418.105.828.215 1.231.318l-.715 2.872l1.727.43l.708-2.84c.472.127.93.245 1.378.357l-.706 2.828l1.728.43l.715-2.866c2.948.558 5.164.333 6.097-2.333c.752-2.146-.037-3.385-1.588-4.192c1.13-.26 1.98-1.003 2.207-2.538m-3.95 5.538c-.533 2.147-4.148.986-5.32.695l.95-3.805c1.172.293 4.929.872 4.37 3.11m.535-5.569c-.487 1.953-3.495.96-4.47.717l.86-3.45c.975.243 4.118.696 3.61 2.733"/></svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><g fill="currentColor" fill-rule="evenodd"><path d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16s-7.163 16-16 16m7.994-15.781L16.498 4L9 16.22l7.498 4.353zM24 17.616l-7.502 4.351L9 17.617l7.498 10.378z"/><g fill-rule="nonzero"><path fill-opacity=".298" d="M16.498 4v8.87l7.497 3.35zm0 17.968v6.027L24 17.616z"/><path fill-opacity=".801" d="m16.498 20.573l7.497-4.353l-7.497-3.348z"/><path fill-opacity=".298" d="m9 16.22l7.498 4.353v-7.701z"/></g></g></svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="currentColor" fill-rule="evenodd" d="M16 32C7.163 32 0 24.837 0 16S7.163 0 16 0s16 7.163 16 16s-7.163 16-16 16m1.922-18.207v-2.366h5.414V7.819H8.595v3.608h5.414v2.365c-4.4.202-7.709 1.074-7.709 2.118c0 1.044 3.309 1.915 7.709 2.118v7.582h3.913v-7.584c4.393-.202 7.694-1.073 7.694-2.116c0-1.043-3.301-1.914-7.694-2.117m0 3.59v-.002c-.11.008-.677.042-1.942.042c-1.01 0-1.721-.03-1.971-.042v.003c-3.888-.171-6.79-.848-6.79-1.658c0-.809 2.902-1.486 6.79-1.66v2.644c.254.018.982.061 1.988.061c1.207 0 1.812-.05 1.925-.06v-2.643c3.88.173 6.775.85 6.775 1.658c0 .81-2.895 1.485-6.775 1.657"/></svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M11 13H5v-2h6V5h2v6h6v2h-6v6h-2z"/></svg>
            
            </div>
          </div>

          <div className="footer-divide"></div>

          <div className="footer-credits">
            <p>2023 Hoodcheats ltd. All rights reserved.</p>
            <p>Designed and created by BobbySmurf</p>
          </div>
          </nav>
       </footer>
    )
}