import React from "react";
import { BrowserRouter as Router, Route, Routes, Switch, useLocation } from 'react-router-dom';
import {Home} from '../pages/home.js'
import {All} from '../pages/all.js'
import {Cheats} from '../pages/cheats.js'
import {Product} from '../pages/product.js'
import {Status} from '../pages/status.js'
import {Store} from '../pages/store.js'
import {Policy} from '../pages/policy.js'
import {About} from '../pages/about.js'
import {Test} from '../pages/test.js'
import {Sitemap} from '../pages/sitemap.js'

import { AnimatePresence } from 'framer-motion'

export const AnimatedRoutes = () => {

  const location = useLocation();

  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/status" element={<Status />} /> 
        <Route path="/policy" element={<Policy />} />
        <Route path="/about" element={<About />} />
        <Route path="/sitemap" element={<Sitemap />} />
        <Route path="/games" element={<All />} />
        <Route path="/store/:gameUrl" element={<Store />} />
        <Route path="/games/:gameUrl" element={<Cheats />} />
        <Route path="/games/:gameUrl/:cheatUrl" element={<Product />} />
      </Routes>
    </AnimatePresence>
  )
};

export default AnimatedRoutes;
