import React, {useState, useEffect} from "react";
import { Link } from 'react-router-dom';
import { cheats } from "../components/cheats-data";
import { Helmet } from 'react-helmet';
import { Checkboxes } from '../components/checkbox-data.js'
import rust from '../images/backgrounds/rustimage.webp'
import logo from '../images/logo.webp'

import { motion } from 'framer-motion'

export const All = () => {


  const [searchItem, setSearchItem] = useState('')
  const [minPreValue, setPreMinValue] = useState(null);
  const [maxPreValue, setPreMaxValue] = useState(null);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);

  const [selectedProviderFilters, setSelectedProviderFilters] = useState([]);
  const [selectedDealFilters, setSelectedDealFilters] = useState([]);
  const [selectedTagFilters, setSelectedTagFilters] = useState([]);

  const [marginTop, setMarginTop] = useState(0);
  const [lastValidMarginTop, setLastValidMarginTop] = useState(0);

  const [dragStartX, setDragStartX] = useState(0);

  const [initialScrollLeft, setInitialScrollLeft] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const [sortFilters, setSortFilters] = useState([]);
  const [activeFilter, setActiveFilter] = useState();

  
  const [sortedCheats, setSortedCheats] = useState([]);
  const [activeMobileFilter, setActiveMobileFilter] = useState(false);



  const handleDragStart = (e) => {

    e.preventDefault();
    setDragStartX(e.clientX);
    setInitialScrollLeft(e.target.scrollLeft);
    setIsDragging(true);
  };

  const handleDrag = (e) => {
    if (!isDragging) return;

    const dragDistance = e.clientX - dragStartX;
    e.target.scrollLeft = initialScrollLeft - dragDistance;
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };



  useEffect(() => {

    const handleScroll = () => {

    };


    setSortedCheats(sortSponsored(cheats));
  


    // Add a scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount

  const filterStyle = {
    transform: `translateY(${marginTop}px`,
  };



  const handleInputChange = (e) => { 
    
    const searchItem = e.target.value;

    setSearchItem(searchItem);

  }

  const handleMinInputChange = (e) => {
    const value = e.target.value;

    if(!isNaN(value)) {
      setPreMinValue(value);
    } else {
      setPreMinValue(null);
    }

  };

  const handleMaxInputChange = (e) => {
    const value = e.target.value;

    if(!isNaN(value)) {
      setPreMaxValue(value);

    } else {
      setPreMaxValue(null);
    }

  };


  const handlePriceSubmit = () => {
     setMaxValue(maxPreValue)
     setMinValue(minPreValue)
  };



  const checkSearch = (cheat) => {
    if (searchItem.length > 0) {
      return cheat.name.toLowerCase().includes(searchItem.toLowerCase());
    } else {
      return true;
    }
  }

  const checkPrice = (cheat) => {

    if (minValue <= 0 && maxValue <= 0) {
      return true;
    } else {
      return cheat.day >= minValue && cheat.day <= maxValue;
    }
  }

  const checkDeals = (cheat) => {
    if (selectedDealFilters.length !== 0) {
      return selectedDealFilters.some((deal) => {
        return cheat.genre.Deals && cheat.genre.Deals.some((cheatDeal) => cheatDeal === deal);
      });
    }
    return true;
  }

  const checkProvider = (cheat) => {
    if (selectedProviderFilters.length !== 0) {
      return selectedProviderFilters.some((provider) => {
        return cheat.name === provider;
      });
    }
    return true;
  }

  const checkTags = (cheat) => {
    if (selectedTagFilters.length !== 0) {
      return selectedTagFilters.some((tag) => {
        return cheat.genre.Tags && cheat.genre.Tags.some((cheatTag) => cheatTag === tag);
      });
    }
    return true;
  }


  const checkBoxes = (cheat) => {
      if(selectedDealFilters.length === 0 && selectedProviderFilters.length === 0 && selectedTagFilters.length === 0) {
        return true;
      }

      return checkDeals(cheat) && checkTags(cheat) && checkProvider(cheat);

  };



  const handleCheckboxChange = (box) => {
    Checkboxes.forEach((boxes) => {
      if(boxes.genre === "Providers") {
        if(boxes.checkbox.some((check) => check === box)) {
          if(selectedProviderFilters.includes(box)) {
            setSelectedProviderFilters(selectedProviderFilters.filter((item) => item !== box));
          } else {
            setSelectedProviderFilters([...selectedProviderFilters, box]);
          }

        };
      } else if (boxes.genre === "Cheat Tags") {
        if(boxes.checkbox.some((check) => check === box)) {
          if(selectedTagFilters.includes(box)) {
            setSelectedTagFilters(selectedTagFilters.filter((item) => item !== box));
          } else {
            setSelectedTagFilters([...selectedTagFilters, box]);
          }

        };
      } else if (boxes.genre === "Deals") {
        if(boxes.checkbox.some((check) => check === box)) {
          if(selectedDealFilters.includes(box)) {
            setSelectedDealFilters(selectedDealFilters.filter((item) => item !== box));
          } else {
            setSelectedDealFilters([...selectedDealFilters, box]);
          }

        };
      }
    });
  };

  const cheatMatchesFilter = (cheat) => {

    return checkSearch(cheat) && checkPrice(cheat) && checkBoxes(cheat);

  }

  const handleSortBtnChange = (e) => {

    const sortValue = e.target.value

    if(sortFilters.includes(sortValue)) {
      setSortFilters(sortFilters.filter((item) => item !== sortValue));
    } else {
      setSortFilters([...sortFilters, sortValue]);
    }

  };

  const sortByPriceDescending = (cheats) => {
    return cheats.slice().sort((a, b) => b.day - a.day);
  };

  const sortByPriceAscending = (cheats) => {
    return cheats.slice().sort((a, b) => a.day - b.day);
  };

  const sortSponsored = (cheats) => {
    return cheats.slice();
  }

  const toggleBtn = (index) => {
    let newSortedCheats;

    if (index !== activeFilter) {
      setActiveFilter(index);
    } else {
      setActiveFilter(null);
    }

    if (index === 1) {
      newSortedCheats = sortByPriceAscending(cheats);
    } else if (index === 2) {
      newSortedCheats = sortByPriceDescending(cheats);
    } else {
      newSortedCheats = sortSponsored(cheats);
    }

    setSortedCheats(newSortedCheats);
  };

  const hasCheatCards = cheats.some(cheat => cheatMatchesFilter(cheat));

    return (
      <>
      <Helmet>
        <title>{`Hoodcheats All Games`}</title>
        <meta name="description" content={`Description for All Games Page`} />
      </Helmet>
      <motion.main
          initial={{width: 0, opacity: 0}}
          animate={{width: "100%", opacity: 1}}
          exit={{ x: window.innerWidth, opacity: 0, transition: {duration: 0.1}}}
        >

      <div className={activeMobileFilter ? "mobile-filter mobile-filter-active" : "mobile-filter"}>
          <div className="mobile-filter-header">
            <div className="mobile-filter-btn" onClick={() => setActiveMobileFilter(!activeMobileFilter)}>Filter <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="#dfe4e4" d="M12 14.975q-.2 0-.375-.062T11.3 14.7l-4.6-4.6q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l3.9 3.9l3.9-3.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-4.6 4.6q-.15.15-.325.213t-.375.062"/></svg></div>
          </div>
          <div className="mobile-filter-body">
            <div className="mobile-searchbar">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m19.6 21l-6.3-6.3q-.75.6-1.725.95T9.5 16q-2.725 0-4.612-1.888T3 9.5q0-2.725 1.888-4.612T9.5 3q2.725 0 4.613 1.888T16 9.5q0 1.1-.35 2.075T14.7 13.3l6.3 6.3zM9.5 14q1.875 0 3.188-1.312T14 9.5q0-1.875-1.312-3.187T9.5 5Q7.625 5 6.313 6.313T5 9.5q0 1.875 1.313 3.188T9.5 14"/></svg>
              <input type="text" value={searchItem} onChange={handleInputChange} placeholder='Search Cheats' />
            </div>
            <p className="mobile-tooltip">Set Price</p>
            <div className="mobile-minmax">
              <div className="limits">
                <input type="text" id="minvalue" placeholder="Min" value={minPreValue} onChange={handleMinInputChange}
                 />
                <input type="text" id="maxvalue" placeholder="Max" value={maxPreValue} onChange={handleMaxInputChange}
                />
              </div>
                <input className="filter-submit" type="submit" value="Submit" onClick={() => handlePriceSubmit()} />
            </div>
            <p className="mobile-tooltip">Sort by</p>
            <div className="mobile-sort-btns">
              <button className={activeFilter === 1 ? "sort-btn sort-active" : "sort-btn"} value="PriceAsc" onClick={() => toggleBtn(1)}>Price Low to High</button>
              <button className={activeFilter === 2 ? "sort-btn sort-active" : "sort-btn"} value="PriceDes" onClick={() => toggleBtn(2)}>Price High to Low</button>
              <button className={activeFilter === 3 ? "sort-btn sort-active" : "sort-btn"} value="Sponsored" onClick={() => toggleBtn(3)}>Sponsored</button>
            </div>
            <div className="mobile-checkboxes">
            {Checkboxes.map((checkbox) => {
              return (
              <div className="mobile-checkbox-list">
              <p className="mobile-checkbox-genre">{checkbox.genre}</p>
              {checkbox.checkbox.map((box) => {
                return (
                  <div className="mobile-checkbox">
                    <input type="checkbox" id="box" value={`${box}`} onChange={() => handleCheckboxChange(box)}></input>
                    <label>{box}</label>
                    <span className="mobile-checkmark"></span>
                  </div>
                );
              })}
              </div>
              );
            })}
            </div>
          </div>
        </div>
        <div className="breadcrumbs"><p><Link aria-label="Link to all cheats" to={`/games`}>All Games</Link></p></div>
        <header className="cheat-header">All Games</header>
        <div className="cheats-page">
          <div className="filter" style={filterStyle}>
            <div className="searchbar">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m19.6 21l-6.3-6.3q-.75.6-1.725.95T9.5 16q-2.725 0-4.612-1.888T3 9.5q0-2.725 1.888-4.612T9.5 3q2.725 0 4.613 1.888T16 9.5q0 1.1-.35 2.075T14.7 13.3l6.3 6.3zM9.5 14q1.875 0 3.188-1.312T14 9.5q0-1.875-1.312-3.187T9.5 5Q7.625 5 6.313 6.313T5 9.5q0 1.875 1.313 3.188T9.5 14"/></svg>
            <input type="text" value={searchItem} onChange={handleInputChange} placeholder='Search Cheats' />
            </div>
            <p className="tooltip">Set Price</p>
            <div className="minmax">
              <div className="limits">
                <input type="text" id="minvalue" placeholder="Min" value={minPreValue} onChange={handleMinInputChange}
                 />
                <input type="text" id="maxvalue" placeholder="Max" value={maxPreValue} onChange={handleMaxInputChange}
                />
              </div>
                <input className="filter-submit" type="submit" value="Submit" onClick={() => handlePriceSubmit()} />
            </div>

            <p className="tooltip">Sort by</p>
            <button className={activeFilter === 1 ? "sort-btn sort-active" : "sort-btn"} value="PriceAsc" onClick={() => toggleBtn(1)}>Price Low to High</button>
            <button className={activeFilter === 2 ? "sort-btn sort-active" : "sort-btn"} value="PriceDes" onClick={() => toggleBtn(2)}>Price High to Low</button>
            <button className={activeFilter === 3 ? "sort-btn sort-active" : "sort-btn"} value="Sponsored" onClick={() => toggleBtn(3)}>Sponsored</button>
          

            {Checkboxes.map((checkbox) => {
              return (
              <>
              <p className="checkbox-genre">{checkbox.genre}</p>
              {checkbox.checkbox.map((box) => {
                return (
                  <div className="checkbox">
                    <input type="checkbox" id="box" value={`${box}`} onChange={() => handleCheckboxChange(box)}></input>
                    <label>{box}</label>
                    <span className="checkmark"></span>
                  </div>
                );
              })}
              </>
              );
            })}


          </div>
          <div className={hasCheatCards ? "cheats-full" : "cheats-full cheats-full-empty"}>
          <div className="empty">Looks like nothing matches your filters...</div>
          <div className="hints"><p>Tip: Drag the tags to see more!</p></div>

            {sortedCheats.map((cheat, index) => {
              return (cheatMatchesFilter(cheat) ? (
              <>
                  <div className="cheat-card">


                    <div draggable="false"  className="cheat-tags-slider-mask">
                    
                    <div draggable="false" className="cheat-tags-slider">
                    <div className="cheat-tags" onMouseDown={handleDragStart} onMouseMove={handleDrag} onMouseUp={handleDragEnd} onMouseLeave={handleDragEnd}>
                      {cheat.genre.Tags.map((tag) => {
                        return <div className={"cheat-tag " + tag.toLowerCase()}>{tag.toUpperCase()}</div>
                      })}
                    </div>
                    </div>
                    </div>
                    <Link aria-label={`Link to ${cheat.game} ${cheat.name}`} to={`/games/${cheat.game}/${cheat.name}`}>
                    <div className="cheat-link-container">
                      <div className="cheat-image">
                       <img alt={`${cheat.game} ${cheat.name}`} draggable="false" src={rust}/>
                       <img alt={`${cheat.name} logo`}draggable="false" className="cheat-logo"src={logo}></img>
                      </div>
                      <div className="cheat-info">
                        <div className="cheat-info-col">
                          <div className="cheat-name">
                              <h1>{cheat.name}</h1>
                          </div>
                          <div className="cheat-range">
                            <p>Starting at: <h3>${cheat.day}</h3></p>
                          </div>
                        </div>
                          <div className="cheat-stars">
                              <h3>4.9</h3>
                              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2L9.19 8.63L2 9.24l5.46 4.73L5.82 21z"/></svg>
                          </div>
                      </div>
                    </div>
                    </Link>
                  </div>
              </>
              ) : (
              <></>
              )
              );
             
            })}
          </div>
        </div>
      </motion.main>
      </>
    );

}
