import React, {useState, useEffect} from "react";
import {Link} from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { gamesdata } from "../components/cheats-data.js";
import axios from "axios";
import { Helmet } from 'react-helmet';
import { Checkboxes } from '../components/checkbox-data.js'
import rust from '../images/backgrounds/rustimage.webp'
import { motion } from 'framer-motion'



export const Store = () => {
    const { gameUrl } = useParams();


    const sellAllAPIKey = "t9cmwcimmf8ltg92l0371s5o1buegtriyfplgyodbzn2vi7ivmcjnugut4zp7yb0qul4ks8xekczvcqi08q7sxjaukadaazqhbzh1eq3r5ebsnjnjwm4thng228miy09";
    const [cheats, setCheats] = useState([]);
    const [setGameLinked, gameLinked] = useState();
    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
        const fetchSellAllProducts = async () => {
            try {
  
              const response = await axios.get('https://api.sellall.cc/v1/products/', {
                  method: 'get',
                  maxBodyLength: Infinity,
                  headers: {
                      'x-sellall-auth': sellAllAPIKey
                  }
              });
              
              const productsList = await response.data.map(product => {
                  let info = {};
                  try {
                      info = product.longDescription ? JSON.parse(product.longDescription) : {};
                  } catch (error) {
                      console.error('Error parsing longDescription:', error);
                  }

                  return {
                      name: product.name,
                      description: "Description Here",
                      imageSrc: `https://res.cloudinary.com/sellall/image/upload/f_auto/q_auto/${product.imageID}`,
                      isVisible: product.visibility === "public",
                      displayPrice: product.variants[0].price,
                      embedID: product._id,
                      /* Product Details */
                      game: info.game, //NEEDED
                      tags: info.genre.Tags, //NEEDEDS
                      provider: info.genre.provider, //NEEDED
                  };
              });
  
                setCheats(productsList)

                if(productsList.some((product) => product.game === gameUrl)) {
                    setGameLinked(gameUrl);
                } else {
                    setGameLinked("Test");
                }

                console.log(productsList)
                setIsLoading(false);
              
            } catch (error) {
                console.error('Error fetching products:', error);
                setIsLoading(false);
            }
        };
  
        fetchSellAllProducts();
    }, [sellAllAPIKey]);
    
    

  
    if(!isLoading && gameLinked !== null) {

      return (
        <>
        <Helmet>
        <title>{`Hoodcheats ${gameLinked} Cheating`}</title>
        <meta name="description" content={`Description for ${gameLinked} Page`} />
        </Helmet>
        <motion.main
        initial={{width: 0, opacity: 0}}
        animate={{width: "100%", opacity: 1}}
        exit={{ x: window.innerWidth, opacity: 0, transition: {duration: 0.1}}}
        >
        <div className="breadcrumbs">
          <p><Link aria-label="Link to all cheats" to={`/games`}>All Games</Link></p>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="#dfe4e4" d="M12 14.975q-.2 0-.375-.062T11.3 14.7l-4.6-4.6q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l3.9 3.9l3.9-3.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-4.6 4.6q-.15.15-.325.213t-.375.062"/></svg>
          <p><Link aria-label={`Link to ${gameLinked} ${gameLinked}`} to={`/games/${gameLinked}`}>{gameLinked}</Link></p>
        </div>
        <header className="cheat-header">{gameLinked}</header>

        


        </motion.main>

        </>
      )
    }
    return <h1>ERROR</h1>


}
