import hood from '../images/logos/cheatlogos/hood.webp'
import React, {useState, useEffect} from "react";
    
import {Link} from 'react-router-dom';

import rust from '../images/backgrounds/rustimage.webp'
import apex from '../images/backgrounds/apex.webp'
import fortnite from '../images/backgrounds/fortnite.webp'
import { gamescategory } from '../components/games-categories-data'
import logo from '../images/logo.webp'
import { cheats } from '../components/cheats-data'
import { providerdata } from '../components/cheats-data'

import forumsbtn from '../images/FORUMSBTN.webp'
import storebtn from '../images/STOREBTN.webp'

import baunti from '../images/logos/cheatlogos/hoodbaunti.webp'
import cola from '../images/logos/cheatlogos/hoodcola.webp'
import disarray from '../images/logos/cheatlogos/hooddisarray.webp'
import fluent from '../images/logos/cheatlogos/hoodfluent.webp'
import pixel from '../images/logos/cheatlogos/hoodpixel.webp'
import quantum from '../images/logos/cheatlogos/hoodquantum.webp'



import rusticon from '../images/logos/gamelogos/rust.webp'
import apexicon from '../images/logos/gamelogos/apex.webp'
import armaicon from '../images/logos/gamelogos/arma.webp'
import arkicon from '../images/logos/gamelogos/ark.webp'
import tarkovicon from '../images/logos/gamelogos/tarkov.webp'
import deadicon from '../images/logos/gamelogos/dead.webp'
import scumicon from '../images/logos/gamelogos/scum.webp'
import cs2icon from '../images/logos/gamelogos/cs2.webp'
import dayzicon from '../images/logos/gamelogos/dayz.webp'
import seaicon from '../images/logos/gamelogos/sea.webp'
import waricon from '../images/logos/gamelogos/warthunder.webp'
import fortniteicon from '../images/logos/gamelogos/fortnite.webp'
import daysicon from '../images/logos/gamelogos/dayz.webp'
import finalsicon from '../images/logos/gamelogos/finals.webp'


import rustactiveicon from '../images/logos/gamelogos/rustactive.webp'
import apexactiveicon from '../images/logos/gamelogos/apexactive.webp'
import tarkovactiveicon from '../images/logos/gamelogos/tarkovactive.webp'
import armaactiveicon from '../images/logos/gamelogos/armaactive.webp'
import scumactiveicon from '../images/logos/gamelogos/scum active.webp'
import dayzactiveicon from '../images/logos/gamelogos/dayzactive.webp'
import seaactiveicon from '../images/logos/gamelogos/seaactive.webp'
import waractiveicon from '../images/logos/gamelogos/warthunderactive.webp'
import fortniteactiveicon from '../images/logos/gamelogos/fortniteactive.webp'
import daysactiveicon from '../images/logos/gamelogos/dayzactive.webp'
import finalsactiveicon from '../images/logos/gamelogos/finalsactive.webp'
import arkactiveicon from '../images/logos/gamelogos/arkactive.webp'
import deadactiveicon from '../images/logos/gamelogos/deadactive.webp'
import cs2activeicon from '../images/logos/gamelogos/cs2active.webp'
import undefined from '../images/logos/gamelogos/undefined.webp'


import apexcharacter from '../images/characters/hoodapexcharacter.webp'
import tarkovcharacter from '../images/characters/hoodtarkovcharacter.webp'
import fortnitecharacter from '../images/characters/hoodfortnitecharacter.webp'
import valorantcharacter from '../images/characters/hoodvalcharacter.webp'
import finalscharacter from '../images/characters/hoodfinalscharacter.webp'

import apexlogo from '../images/logos/gamelogos/apexlogo.webp'
import tarkovlogo from '../images/logos/gamelogos/tarkovlogo.webp'
import fortnitelogo from '../images/logos/gamelogos/fortnitelogo.webp'
import valorantlogo from '../images/logos/gamelogos/valorantlogo.webp'
import finalslogo from '../images/logos/gamelogos/finalslogo.webp'

import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';

import { motion } from 'framer-motion'





export const Home = () => {
    const [dragStartX, setDragStartX] = useState(0);
    const [initialScrollLeft, setInitialScrollLeft] = useState(0);
    const [isDragging, setIsDragging] = useState(false);


    const [activeProvider, setActiveProvider] = useState(0);

    const [cheatProvider, setProvider] = useState("Quantum Cheats");
    const [cheatGame, setCheatGame] = useState();

    const [activeCheatVideo, setActiveCheatVideo] = useState(null);
    const [hoveredGame, setHoveredGame] = useState(null);

    const lastProviderIndex = providerdata.length;

    const [scrollOnePosition, setScrollOnePosition] = useState(0);
    const [scrollTwoPosition, setScrollTwoPosition] = useState(0);
    const minTranslateY = -120; // Adjust as needed
    const maxTranslateY = 120; // Adjust as needed
    const scrollFactor = .25; // Adjust as needed


  
    useEffect(() => {
        
    const imgOne = document.querySelector('.banner-top');
    const imgTwo = document.querySelector('.banner-two');

     const oneLocation = ((imgOne.getBoundingClientRect().top + window.scrollY) - imgOne.getBoundingClientRect().height / 2);

        

     const twoLocation = ((imgTwo.getBoundingClientRect().top + window.scrollY) - imgTwo.getBoundingClientRect().height / 2);


      const handleScroll = () => {
        console.log(window.scrollY - oneLocation)

        if(Math.abs(window.scrollY - oneLocation) < 500) {
            setScrollOnePosition(window.scrollY - oneLocation);
        }
        if(Math.abs(window.scrollY - twoLocation) < 500) {
            setScrollTwoPosition(window.scrollY - twoLocation);
        }

      };
  
      window.addEventListener('scroll', handleScroll);
  
      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
        const translateOneY = Math.min(
        maxTranslateY,
        Math.max(minTranslateY, -scrollOnePosition * scrollFactor)
      );

      const translateTwoY = Math.min(
        maxTranslateY,
        Math.max(minTranslateY, -scrollTwoPosition * scrollFactor)
      );



    const handleDragStart = (e) => {

        e.preventDefault();
        setDragStartX(e.clientX);
        setInitialScrollLeft(e.target.scrollLeft);
        setIsDragging(true);
      };
    
      const handleDrag = (e) => {
        if (!isDragging) return;

        const dragDistance = e.clientX - dragStartX;
        e.target.scrollLeft = initialScrollLeft - dragDistance;
      };
    
      const handleDragEnd = () => {
        setIsDragging(false);
      };


      const cheatisPopular = (cheat, game) => {
        return cheat.genre.Tags.some((item) => item === "Popular") && cheat.game === game;
      };

      const goLeft = () => {
        
        if (activeProvider - 1 === -1) {
            console.log("ran");
            setProvider(providerdata[lastProviderIndex - 1]);
            setActiveProvider(lastProviderIndex - 1);
        } else {
            setProvider(providerdata[activeProvider - 1]);
            setActiveProvider(activeProvider - 1);
        }

        setCheatGame(null);

        setActiveCheatVideo(null);

      }

      const goRight = () => {
        if (activeProvider + 1 === lastProviderIndex) {
            setProvider(providerdata[0]);
            setActiveProvider(0);
        } else {
            setProvider(providerdata[activeProvider + 1]);
            setActiveProvider(activeProvider + 1);
        }

        setCheatGame(null);

        setActiveCheatVideo(null);

      }

      const changeProvider = (index, provider) => {
        if (index === activeProvider) {

            return true;
        }

        return false;
      }
    

      const chooseGame = (game) => {
            cheats.forEach(cheat => {
                if (cheat.game === game && cheat.name === cheatProvider) {
                    setCheatGame(cheat.game);
                    setActiveCheatVideo(cheat.featuredVideo);
                }
            });

      }

      const getImageSource = (game, isActive) => {
        if (!isActive) {

            if (game === 'Rust') {
                return (hoveredGame === 'Rust' || cheatGame === 'Rust') ? rustactiveicon : rusticon;
            } else if (game === 'Apex Legends') {
                return (hoveredGame === 'Apex Legends' || cheatGame === 'Apex Legends') ? apexactiveicon : apexicon;
            } else if (game === 'Escape from Tarkov') {
                return (hoveredGame === 'Escape from Tarkov' || cheatGame === 'Escape from Tarkov') ? tarkovactiveicon : tarkovicon;
            } else if (game === 'Arma 3') {
                return (hoveredGame === 'Arma 3' || cheatGame === 'Arma 3') ? armaactiveicon : armaicon;
            } else if (game === 'Scum') {
                return (hoveredGame === 'Scum' || cheatGame === 'Scum') ? scumactiveicon : scumicon;
            } else if (game === 'Dayz') {
                return (hoveredGame === 'Dayz' || cheatGame === 'Dayz') ? dayzactiveicon : dayzicon;
            } else if (game === 'Sea of Thieves') {
                return (hoveredGame === 'Sea of Thieves' || cheatGame === 'Sea of Thieves') ? seaactiveicon : seaicon;
            } else if (game === 'War Thunder') {
                return (hoveredGame === 'War Thunder' || cheatGame === 'War Thunder') ? waractiveicon : waricon;
            } else if (game === 'Fortnite') {
                return (hoveredGame === 'Fortnite' || cheatGame === 'Fortnite') ? fortniteactiveicon : fortniteicon;
            } else if (game === '7 Days To Die') {
                return (hoveredGame === '7 Days To Die' || cheatGame === '7 Days To Die') ? daysactiveicon : daysicon;
            } else if (game === 'The Finals') {
                return (hoveredGame === 'The Finals' || cheatGame === 'The Finals') ? finalsactiveicon : finalsicon;
            } else if (game === 'Ark: Survival Ascended') {
                return (hoveredGame === 'Ark: Survival Ascended' || cheatGame === 'Ark: Survival Ascended') ? arkactiveicon : arkicon;
            } else if (game === 'Dead By Daylight') {
                return (hoveredGame === 'Dead By Daylight' || cheatGame === 'Dead By Daylight') ? deadactiveicon : deadicon;
            } else if (game === 'Counter Strike 2') {
                return (hoveredGame === 'Counter Strike 2' || cheatGame === 'Counter Strike 2') ? cs2activeicon : cs2icon;
            } else {
                return undefined;
            }
        } else {
            if (game === 'Rust') {
                return rustactiveicon;
            } else if (game === 'Apex Legends') {
                return apexactiveicon;
            } else if (game === 'Arma 3') {
                return armaactiveicon;
            } else if (game === 'Ark: Survival Ascended') {
                return arkactiveicon;
            } else if (game === 'Dead By Daylight') {
                return deadactiveicon;
            } else if (game === 'Escape from Tarkov') {
                return tarkovactiveicon;
            } else if (game === 'Scum') {
                return scumactiveicon;
            } else if (game === 'Dayz') {
                return dayzactiveicon;
            } else if (game === 'Sea of Thieves') {
                return seaactiveicon;
            } else if (game === 'War Thunder') {
                return waractiveicon;
            } else if (game === 'Fortnite') {
                return fortniteactiveicon;
            } else if (game === '7 Days To Die') {
                return  daysactiveicon;
            } else if (game === 'The Finals') {
                return finalsactiveicon;
            } else if (game === 'Ark: Survival Ascended') {
                return arkactiveicon;
            } else if (game === 'Dead By Daylight') {
                return deadactiveicon;
            } else if (game === 'Counter Strike 2') {
                return cs2activeicon;
            } else {
                return undefined;
            }
        }
      };

      const checkSelectedImage = (index) => {
        if(index === selectedItem){
            return true;
        }
        return false;
      }

      const [selectedItem, setSelectedItem] = useState(0);
      
      const settings = {
        dots: false,
        speed: 500,
        slidesToShow: 3,
        arrows: false,
        centerMode: true,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2500,
        afterChange: (index) => {
            setSelectedItem(index);
            console.log(selectedItem);
        },
      };

      

    return (
    <motion.main className='homepage'
    initial={{width: 0, opacity: 0}}
    animate={{width: "100%", opacity: 1}}
    exit={{ x: window.innerWidth, opacity: 0, transition: {duration: 0.1}}}
    >
        <section className="hero">
            <div className="hero-header">
                <header className="hero-title-text">
                    <span className="title-hood"><img src={hood} alt='Hoodcheats Logo'/>OOD</span> CHEATS
                </header>
                <p className="hero-description">The best cheats you can find in the hood, made in the hood, for the hood.</p>
                <div className="hero-nav-buttons">
                    <Link aria-label='Link to our cheat store' to="/games">
                    <div className='hero-btn'>
                    <img alt="Link to Hoodcheats Cheat Store" src={storebtn}></img>
                    <h3>STORE</h3>
                    </div>
                    </Link>

                    <Link aria-label='Link to our cheating forums' to="/games">
                    <div className='hero-btn btn-odd'>
                    <img alt="Link to Hoodcheats Cheat Forums" src={forumsbtn}></img>
                    <h3>FORUMS</h3>
                    </div>
                    </Link>
                    
                </div>
            </div>
            <div className='hero-games-showcase'>
                    <div className={checkSelectedImage(0) ? 'hero-showcase hero-showcase-apex' : 'hero-showcase-inactive hero-showcase-apex'} >
                        <img className='hero-showcase-character' src={apexcharacter} alt="Apex Legends cheats character" loading={checkSelectedImage(0) ? '' : 'lazy'}/>
                    </div>
                    <div className={checkSelectedImage(1) ? 'hero-showcase hero-showcase-fortnite' : 'hero-showcase-inactive hero-showcase-fortnite'} >
                        <img className='hero-showcase-character' src={fortnitecharacter} alt="Fortnite cheats character" loading={checkSelectedImage(1) ? '' : 'lazy'}/>
                    </div>
                    <div className={checkSelectedImage(2) ? 'hero-showcase hero-showcase-tarkov' : 'hero-showcase-inactive hero-showcase-tarkov'}>
                        <img className='hero-showcase-character' src={tarkovcharacter} alt="Escape from Tarkov cheats character" loading={checkSelectedImage(2) ? '' : 'lazy'}/>
                    </div>
                    <div className={checkSelectedImage(3) ? 'hero-showcase hero-showcase-val' : 'hero-showcase-inactive hero-showcase-val'}>
                        <img className='hero-showcase-character' src={valorantcharacter} alt="Valorant Legends cheats character" loading={checkSelectedImage(3) ? '' : 'lazy'}/>
                    </div>
                    <div className={checkSelectedImage(4) ? 'hero-showcase hero-showcase-finals' : 'hero-showcase-inactive hero-showcase-finals'}>
                        <img className='hero-showcase-character' src={finalscharacter} alt="The Finals cheats character" loading={checkSelectedImage(4) ? '' : 'lazy'}/>
                    </div>
                    
                    
                    <div className='slider-container'>
                        <Slider {...settings}>
                            <div>
                                <div className='hero-item-container'>
                                    <div className='hero-item'>
                                        <img src={apexlogo} alt='Apex Legends Hacks Cheat Logo'></img>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='hero-item-container'>
                                    <div className='hero-item'>
                                        <img src={fortnitelogo} alt='Fortnite Hacks Cheat Logo'></img>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className='hero-item-container'>
                                    <div className='hero-item'>
                                        <img src={tarkovlogo} alt='Escape from Tarkov Hacks Cheat Logo'></img>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className='hero-item-container'>
                                    <div className='hero-item'>
                                        <img src={valorantlogo} alt='Valorant Hacks Cheat Logo'></img>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className='hero-item-container'>
                                    <div className='hero-item'>
                                        <img src={finalslogo} alt='The Finals Hacks Cheat Logo'></img>
                                    </div>
                                </div>
                            </div>
                            
                        </Slider>
                    </div>
            </div>
        </section>
        


        
        <section class="logos">
            <div class="logos-slide">
                <div className='logo-item'>
                    <img src={baunti} alt='Baunticheat logo'/>
                    <h1>BAUNTICHEATS</h1>
                </div>
                <div className='logo-item'>
                    <img src={cola} alt='Cola logo'/>
                    <h1>COLA</h1>
                </div>
                <div className='logo-item'>
                    <img src={disarray} alt='Disarray logo'/>
                    <h1>DISARRAY</h1>
                </div>
                <div className='logo-item'>
                    <img src={fluent} alt='Fluent logo'/>
                    <h1>FLUENT</h1>
                </div>
                <div className='logo-item'>
                    <img src={pixel} alt='Pixel Perfect logo'/>
                    <h1>PIXELPERFECT</h1>
                </div>
                <div className='logo-item'>
                    <img src={quantum} alt='Quantum logo'/>
                    <h1>QUANTUMCHEATS</h1>
                </div>
                <div className='logo-item'>
                    <img src={baunti} alt='Baunticheat logo'/>
                    <h1>BAUNTICHEATS</h1>
                </div>
                <div className='logo-item'>
                    <img src={cola} alt='Cola logo'/>
                    <h1>COLA</h1>
                </div>
                <div className='logo-item'>
                    <img src={disarray} alt='Disarray logo'/>
                    <h1>DISARRAY</h1>
                </div>
                <div className='logo-item'>
                    <img src={fluent} alt='Fluent logo'/>
                    <h1>FLUENT</h1>
                </div>
                <div className='logo-item'>
                    <img src={pixel} alt='Pixel Perfect logo'/>
                    <h1>PIXELPERFECT</h1>
                </div>
                
                
                
            </div>

            <div class="logos-slide">
            <div className='logo-item'>
                    <img src={baunti} alt='Baunticheat logo'/>
                    <h1>BAUNTICHEATS</h1>
                </div>
                <div className='logo-item'>
                    <img src={cola} alt='Cola logo'/>
                    <h1>COLA</h1>
                </div>
                <div className='logo-item'>
                    <img src={disarray} alt='Disarray logo'/>
                    <h1>DISARRAY</h1>
                </div>
                <div className='logo-item'>
                    <img src={fluent} alt='Fluent logo'/>
                    <h1>FLUENT</h1>
                </div>
                <div className='logo-item'>
                    <img src={pixel} alt='Pixel Perfect logo'/>
                    <h1>PIXELPERFECT</h1>
                </div>
                <div className='logo-item'>
                    <img src={quantum} alt='Quantum logo'/>
                    <h1>QUANTUMCHEATS</h1>
                </div>
                <div className='logo-item'>
                    <img src={baunti} alt='Baunticheat logo'/>
                    <h1>BAUNTICHEATS</h1>
                </div>
                <div className='logo-item'>
                    <img src={cola} alt='Cola logo'/>
                    <h1>COLA</h1>
                </div>
                <div className='logo-item'>
                    <img src={disarray} alt='Disarray logo'/>
                    <h1>DISARRAY</h1>
                </div>
                <div className='logo-item'>
                    <img src={fluent} alt='Fluent logo'/>
                    <h1>FLUENT</h1>
                </div>
                <div className='logo-item'>
                    <img src={pixel} alt='Pixel Perfect logo'/>
                    <h1>PIXELPERFECT</h1>
                </div>
            </div>
        </section>

        <section className="hood-stats">
            <div className="hood-stat">
                <h1>15+</h1>
                <p>cheat providers</p>
            </div>
            <div className="hood-stat">
                <h1>~99%</h1>
                <p>clients satisfied</p>
            </div>
            <div className="hood-stat">
                <h1>1,000+</h1>
                <p>cheats sold</p>
            </div>
            
        </section>

        

        <section className="popular-cheats">
        <header className="popular-title"><span><svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24"><path fill="currentColor" d="m12 12.9l-2.13 2.09c-.56.56-.87 1.29-.87 2.07C9 18.68 10.35 20 12 20s3-1.32 3-2.94c0-.78-.31-1.52-.87-2.07z"/><path fill="currentColor" d="m16 6l-.44.55C14.38 8.02 12 7.19 12 5.3V2S4 6 4 13c0 2.92 1.56 5.47 3.89 6.86c-.56-.79-.89-1.76-.89-2.8c0-1.32.52-2.56 1.47-3.5L12 10.1l3.53 3.47c.95.93 1.47 2.17 1.47 3.5c0 1.02-.31 1.96-.85 2.75c1.89-1.15 3.29-3.06 3.71-5.3c.66-3.55-1.07-6.9-3.86-8.52"/></svg> Popular</span> Cheats</header>
            
            {gamescategory.map((object) => {
                return (
                    object.category === "Popular" ? (
                        object.games.map((game, index) => {
                            return (
                                index < 3 ? (
                                <div className="popular-cheat-row">
                                    <div className="popular-header"><h1> <img alt={`${game} icon cheats`} src={getImageSource(game, true)} /> {game}</h1><button>VIEW MORE</button></div>
                                    <div className="cheats">
                                        {cheats.map((cheat, index) => {
                                        return (cheatisPopular(cheat, game) ? (
                                        <>
                                            <div className="cheat-card">
                                                <div draggable="false"  className="cheat-tags-slider-mask"> 
                                                <div draggable="false" className="cheat-tags-slider">
                                                <div className="cheat-tags" onMouseDown={handleDragStart} onMouseMove={handleDrag} onMouseUp={handleDragEnd} onMouseLeave={handleDragEnd}>
                                                {cheat.genre.Tags.map((tag) => {
                                                    return <div className={"cheat-tag " + tag.toLowerCase()}>{tag.toUpperCase()}</div>
                                                })}
                                                </div>
                                                </div>
                                                </div>
                                                <Link aria-label={`Link to ${cheat.game} ${cheat.name}`} to={`/games/${cheat.game}/${cheat.name}`}>
                                                <div className="cheat-link-container">
                                                <div className="cheat-image">
                                                    <img alt={`${cheat.game} ${cheat.name}`} draggable="false" src={rust}/>
                                                    <img alt={`${cheat.name} logo`}draggable="false" className="cheat-logo"src={logo}></img>
                                                </div>
                                                <div className="cheat-info">
                                                    <div className="cheat-info-col">
                                                    <div className="cheat-name">
                                                        <h1>{cheat.name}</h1>
                                                    </div>
                                                    <div className="cheat-range">
                                                        <p>Starting at: <h3>${cheat.day}</h3></p>
                                                    </div>
                                                    </div>
                                                    <div className="cheat-stars">
                                                        <h3>4.9</h3>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2L9.19 8.63L2 9.24l5.46 4.73L5.82 21z"/></svg>
                                                    </div>
                                                </div>
                                                </div>
                                                </Link>
                                            </div>
                                        </>
                                        ) : (
                                        <></>
                                        )
                                        );
                                        
                                        })}
                                        

                                        </div>
                                    <button className='popular-mobile-btn'>VIEW MORE</button>
                                
                                
                                </div>
                                ) : (<></>)
                            )
                        })
                    ) : (
                        <></>
                    )
                )
            })}
            
        </section>


        <section className="provider-gameplay">
            <header>See gameplay from our cheat providers</header>
            <div className="provider-container">

            <div className="provider-media-container">
                <div className="provider-media">
                    {activeCheatVideo === "None" ?
                        <h2>No Video Avaliable</h2>
                        :
                        activeCheatVideo === null ?
                        <h2>Choose Game</h2>
                        :
                        <iframe src={activeCheatVideo} title="YouTube video player" frameborder="0"  allowfullscreen></iframe>
                    }
                </div>  
                <div className="provider-nav">
                <svg onClick={() => goLeft()} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="m10 18l-6-6l6-6l1.4 1.45L7.85 11H20v2H7.85l3.55 3.55z"/></svg>
                {providerdata.map((provider, index) => {
                    return <p className={changeProvider(index, provider) ? "provider-nav-item-active provider-nav-item" : "provider-nav-item"}>{provider}</p>
                })}
                <svg onClick={() => goRight()} xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="m14 18l-1.4-1.45L16.15 13H4v-2h12.15L12.6 7.45L14 6l6 6z"/></svg>
                </div>
            </div>

            <div className="provider-info">
                <div className="provider-games">
                    <h1>GAMES:</h1>
                    <div className="provider-games-list">
                    {cheats.map((cheat) => {

                        return cheat.name === cheatProvider ? (
                        <img
                            key={cheat.game}
                            onClick={() => chooseGame(cheat.game)}
                            src={getImageSource(cheat.game)}
                            onMouseOver={() => setHoveredGame(cheat.game)}
                            onMouseOut={() => setHoveredGame(null)}
                            alt={cheat.name}
                        />
                        ) : (
                        <></>
                        );
                    })}
                    </div>
                    </div>
                <div className="provider-text">
                    <p>{cheatProvider} is the the most popular cheat, why? idk why are you asking me dude  I don't know anything. Quantum Game is the the most popular cheat, why? idk why are you asking me dude  I don't know anything. Quantum Game is the the most popular cheat, why? idk why are you asking me dude  I don't know anything. Quantum Game is the the most popular cheat, why? idk why are you asking me dude  I don't know anything. Quantum Game is the the most popular cheat, why? idk why are you asking me  I don't know anything. Quantum Game is the the most popular cheat...</p>
                </div>
                <div className="provider-view-container">
                    <Link aria-label={`Link to ${cheatGame} ${cheatProvider}`} to={`/games/${cheatGame}/${cheatProvider}`} className={activeCheatVideo === null ? "link-disable" : "link-enabled"}>
                    <button className={activeCheatVideo === null ? "provider-view provider-view-inactive" : "provider-view"}>VIEW CHEAT</button>
                    </Link>
                </div>
            </div>

            </div>
        </section>


        <section className="game-banner banner-top banner-one">
        <div className="banner-container">
            <div className="game-info game-info-right">
                <h1>Apex Legends Hacks</h1>
                <p>IM GONNA NUT IM GONNA NUT IM GONNA NUT IM GONNA NUT IM GONNA NUT IM GONNA NUT IM GONNA NUT IM GONNA NUT </p>
                <Link aria-label="Link to Apex Legends cheats" to="/games/Apex Legends">
                    <button>VIEW GAME</button>
                </Link>
            </div>
            <div className="game-image">
                <img src={apex} style={{ transform: `translateY(${translateOneY}px)` }} loading='lazy' alt='Apex Legends Hacks Cheats' />
            </div>
            </div>
        </section>

        <section className="game-banner banner-two">
            <div className="banner-container">
                <div className="game-image game-image-left">
                <img src={fortnite} style={{ transform: `translateY(${translateTwoY}px)` }} loading='lazy' alt='Fortnite Hacks Cheats' />
                </div>
                <div className="game-info game-info-left">
                    <h1>Fortnite Hacks</h1>
                    <p>IM GONNA NUT IM GONNA NUT IM GONNA NUT IM GONNA NUT IM GONNA NUT IM GONNA NUT IM GONNA NUT IM GONNA NUT </p>
                    <Link aria-label="Link to Fortnite cheats" to="/games/Fortnite">
                    <button>VIEW GAME</button>
                    </Link>
                </div>
            </div>
        </section>

        <section className="choose-us">
            <header>Why Choose Us?</header>
            <div className="choose-container">

                <div className='choose-card'>
                    <h1>Prices</h1>
                    <div className="choose-card-desc">
                        <h3>Fair Prices</h3>
                        <p>Our cheats are priced for their value so that you get your moneys worth.</p>
                    </div>
                </div>

                <div className='choose-card'>
                    <h1>Quality</h1>
                    <div className="choose-card-desc">
                        <h3>Premium Quality</h3>
                        <p>Hoodcheats offers the best quality cheats that you can get.</p>
                    </div>
                </div>
                
                <div className='choose-card'>
                    <h1>Support</h1>
                    <div className="choose-card-desc">
                        <h3>Active Support</h3>
                        <p>Our support is active everyday to help you with any issue you have.</p>
                    </div>
                </div>

                <div className='choose-card'>
                    <h1>Choices</h1>
                    <div className="choose-card-desc">
                        <h3>Tons of choices</h3>
                        <p>We have a large catalog of premium cheats for you to choose from.</p>
                    </div>
                </div>

                <div className='choose-card'>
                    <h1>Updates</h1>
                    <div className="choose-card-desc">
                        <h3>Constant updates</h3>
                        <p>We are constantly updating our catalog of cheats.</p>
                    </div>
                </div>

                <div className='choose-card'>
                    <h1>Ratings</h1>
                    <div className="choose-card-desc">
                        <h3>Premium Quality</h3>
                        <p>We make sure that we sell high quality cheats and our ratings show.</p>
                    </div>
                </div>

            </div>
        </section>

        <section className="reviews">
            <header>What Our Clients Think</header>
            <div className="reviews-container">

                <div className="review-card">
                    <p>HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD </p>
                    <div className="review-card-author">
                    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 24 24"><path fill="currentColor" d="M5.85 17.1q1.275-.975 2.85-1.537T12 15q1.725 0 3.3.563t2.85 1.537q.875-1.025 1.363-2.325T20 12q0-3.325-2.337-5.663T12 4Q8.675 4 6.337 6.338T4 12q0 1.475.488 2.775T5.85 17.1M12 13q-1.475 0-2.488-1.012T8.5 9.5q0-1.475 1.013-2.488T12 6q1.475 0 2.488 1.013T15.5 9.5q0 1.475-1.012 2.488T12 13m0 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22"/></svg>
                        <div className='review-card-author-text'>
                            <h3>BobbySmurda</h3>
                            <p>Long term hood nigga</p>
                        </div>
                    </div>
                </div>

                <div className="review-card">
                    <p>HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD </p>
                    <div className="review-card-author">
                    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 24 24"><path fill="currentColor" d="M5.85 17.1q1.275-.975 2.85-1.537T12 15q1.725 0 3.3.563t2.85 1.537q.875-1.025 1.363-2.325T20 12q0-3.325-2.337-5.663T12 4Q8.675 4 6.337 6.338T4 12q0 1.475.488 2.775T5.85 17.1M12 13q-1.475 0-2.488-1.012T8.5 9.5q0-1.475 1.013-2.488T12 6q1.475 0 2.488 1.013T15.5 9.5q0 1.475-1.012 2.488T12 13m0 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22"/></svg>
                        <div className='review-card-author-text'>
                            <h3>BobbySmurda</h3>
                            <p>Long term hood nigga</p>
                        </div>
                    </div>
                </div>

                <div className="review-card">
                    <p>HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD </p>
                    <div className="review-card-author">
                    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 24 24"><path fill="currentColor" d="M5.85 17.1q1.275-.975 2.85-1.537T12 15q1.725 0 3.3.563t2.85 1.537q.875-1.025 1.363-2.325T20 12q0-3.325-2.337-5.663T12 4Q8.675 4 6.337 6.338T4 12q0 1.475.488 2.775T5.85 17.1M12 13q-1.475 0-2.488-1.012T8.5 9.5q0-1.475 1.013-2.488T12 6q1.475 0 2.488 1.013T15.5 9.5q0 1.475-1.012 2.488T12 13m0 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22"/></svg>
                        <div className='review-card-author-text'>
                            <h3>BobbySmurda</h3>
                            <p>Long term hood nigga</p>
                        </div>
                    </div>
                </div>

                <div className="review-card">
                    <p>HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD </p>
                    <div className="review-card-author">
                    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 24 24"><path fill="currentColor" d="M5.85 17.1q1.275-.975 2.85-1.537T12 15q1.725 0 3.3.563t2.85 1.537q.875-1.025 1.363-2.325T20 12q0-3.325-2.337-5.663T12 4Q8.675 4 6.337 6.338T4 12q0 1.475.488 2.775T5.85 17.1M12 13q-1.475 0-2.488-1.012T8.5 9.5q0-1.475 1.013-2.488T12 6q1.475 0 2.488 1.013T15.5 9.5q0 1.475-1.012 2.488T12 13m0 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22"/></svg>
                        <div className='review-card-author-text'>
                            <h3>BobbySmurda</h3>
                            <p>Long term hood nigga</p>
                        </div>
                    </div>
                </div>

                <div className="review-card">
                    <p>HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD </p>
                    <div className="review-card-author">
                    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 24 24"><path fill="currentColor" d="M5.85 17.1q1.275-.975 2.85-1.537T12 15q1.725 0 3.3.563t2.85 1.537q.875-1.025 1.363-2.325T20 12q0-3.325-2.337-5.663T12 4Q8.675 4 6.337 6.338T4 12q0 1.475.488 2.775T5.85 17.1M12 13q-1.475 0-2.488-1.012T8.5 9.5q0-1.475 1.013-2.488T12 6q1.475 0 2.488 1.013T15.5 9.5q0 1.475-1.012 2.488T12 13m0 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22"/></svg>
                        <div className='review-card-author-text'>
                            <h3>BobbySmurda</h3>
                            <p>Long term hood nigga</p>
                        </div>
                    </div>
                </div>

                <div className="review-card">
                    <p>HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD </p>
                    <div className="review-card-author">
                    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 24 24"><path fill="currentColor" d="M5.85 17.1q1.275-.975 2.85-1.537T12 15q1.725 0 3.3.563t2.85 1.537q.875-1.025 1.363-2.325T20 12q0-3.325-2.337-5.663T12 4Q8.675 4 6.337 6.338T4 12q0 1.475.488 2.775T5.85 17.1M12 13q-1.475 0-2.488-1.012T8.5 9.5q0-1.475 1.013-2.488T12 6q1.475 0 2.488 1.013T15.5 9.5q0 1.475-1.012 2.488T12 13m0 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22"/></svg>
                        <div className='review-card-author-text'>
                            <h3>BobbySmurda</h3>
                            <p>Long term hood nigga</p>
                        </div>
                    </div>
                </div>

                <div className="review-card">
                    <p>HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD </p>
                    <div className="review-card-author">
                    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 24 24"><path fill="currentColor" d="M5.85 17.1q1.275-.975 2.85-1.537T12 15q1.725 0 3.3.563t2.85 1.537q.875-1.025 1.363-2.325T20 12q0-3.325-2.337-5.663T12 4Q8.675 4 6.337 6.338T4 12q0 1.475.488 2.775T5.85 17.1M12 13q-1.475 0-2.488-1.012T8.5 9.5q0-1.475 1.013-2.488T12 6q1.475 0 2.488 1.013T15.5 9.5q0 1.475-1.012 2.488T12 13m0 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22"/></svg>
                        <div className='review-card-author-text'>
                            <h3>BobbySmurda</h3>
                            <p>Long term hood nigga</p>
                        </div>
                    </div>
                </div>

                <div className="review-card">
                    <p>HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD </p>
                    <div className="review-card-author">
                    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 24 24"><path fill="currentColor" d="M5.85 17.1q1.275-.975 2.85-1.537T12 15q1.725 0 3.3.563t2.85 1.537q.875-1.025 1.363-2.325T20 12q0-3.325-2.337-5.663T12 4Q8.675 4 6.337 6.338T4 12q0 1.475.488 2.775T5.85 17.1M12 13q-1.475 0-2.488-1.012T8.5 9.5q0-1.475 1.013-2.488T12 6q1.475 0 2.488 1.013T15.5 9.5q0 1.475-1.012 2.488T12 13m0 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22"/></svg>
                        <div className='review-card-author-text'>
                            <h3>BobbySmurda</h3>
                            <p>Long term hood nigga</p>
                        </div>
                    </div>
                </div>

                <div className="review-card">
                    <p>HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD HOOD </p>
                    <div className="review-card-author">
                    <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 24 24"><path fill="currentColor" d="M5.85 17.1q1.275-.975 2.85-1.537T12 15q1.725 0 3.3.563t2.85 1.537q.875-1.025 1.363-2.325T20 12q0-3.325-2.337-5.663T12 4Q8.675 4 6.337 6.338T4 12q0 1.475.488 2.775T5.85 17.1M12 13q-1.475 0-2.488-1.012T8.5 9.5q0-1.475 1.013-2.488T12 6q1.475 0 2.488 1.013T15.5 9.5q0 1.475-1.012 2.488T12 13m0 9q-2.075 0-3.9-.788t-3.175-2.137q-1.35-1.35-2.137-3.175T2 12q0-2.075.788-3.9t2.137-3.175q1.35-1.35 3.175-2.137T12 2q2.075 0 3.9.788t3.175 2.137q1.35 1.35 2.138 3.175T22 12q0 2.075-.788 3.9t-2.137 3.175q-1.35 1.35-3.175 2.138T12 22"/></svg>
                        <div className='review-card-author-text'>
                            <h3>BobbySmurda</h3>
                            <p>Long term hood nigga</p>
                        </div>
                    </div>
                </div>


            </div>
            
        </section>


    </motion.main>
    );
}