import React, { useRef, useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Link, Route} from 'react-router-dom';
import { gamesdata } from "../components/cheats-data";
import { gamescategory } from "../components/games-categories-data";
import { cheats } from "../components/cheats-data";
import hood from '../images/logos/cheatlogos/hood.webp'

export const Navbar = () => {
    const [open, setOpen] = useState(false);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [storeMobileOpen, setStoreMobileOpen] = useState(false);
    const [openLists, setOpenLists] = useState([1]);
    const [searchItem, setSearchItem] = useState('');



    const openNav = () => {
      const navbar = document.querySelector("nav");
    
      if (navbar) {
      navbar.style.height = `${navbar.scrollHeight}px`;
      navbar.style.maxHeight = `${navbar.scrollHeight}px`;
      }
    };
  
  
    const closedNav = () => {
      const navbar = document.querySelector("nav");
  
      if (navbar) {
      navbar.style.maxHeight = `3rem`;
      }
    };
  
    open ? openNav() : closedNav();



      
    const toggleList = (index) => {
      setOpenLists((prevOpenLists) => {
        let newOpenLists;
        if (!prevOpenLists.includes(index)) {
          openList(index);
          newOpenLists = [...prevOpenLists, index];
        } else {
          closeList(index);
          newOpenLists = prevOpenLists.filter((item) => item !== index);
        }
    
        return newOpenLists;
      });
    };

    const openAllLists = () => {
      if (searchItem.length > 0) {
        return
      } else {
        const listIndexes = [1, 2, 3];

        // Update openLists with the new numbers
        setOpenLists([listIndexes]);
  
        toggleList(1);
        toggleList(2);
        toggleList(3);
      }

    };

    
    useEffect(() => {
      if(open) {
        updateNavbarHeight();
      }

    }, [openLists]);
  

    
    const updateNavbarHeight = () => {
      const navbar = document.querySelector("nav");
      if (navbar) {
        navbar.style.height = "fit-content";
        navbar.style.maxHeight = `35rem`;
      }
    };


    
    const openList = (index) => {
      switch (index) {
        case 1:
          openPopularList();
          break;
        case 2:
          openNewList();
          break;
        case 3:
          openOtherList();
          break;

        default:
      
          break;
      }
    };
    
    const closeList = (index) => {
      switch (index) {
        case 1:
          closePopularList();
          break;
        case 2:
          closeNewList();
          break;
        case 3:
          closeOtherList();
          break;

        default:
        
          break;
      }
    };

    useEffect(() => {

      toggleList(1);
      toggleList(2);
      toggleList(3);

      const handleResize = () => {
        if (window.innerWidth <= 801) {
          setOpen(false);

        } else {
          setMobileOpen(false);
        }
        
      };

  
      // Add a scroll event listener
      window.addEventListener('resize', handleResize);
  
      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []) // Empty dependency array ensures the effect runs only once on mount

  
    const navbarRef = useRef(null);
    
  
    const [selectedGame, setSelectedGame] = useState(null);

  
    const handleGameClick = (game) => {
      
      setSelectedGame(game);
      
    };

    

    const openPopularList = () => {
      const popular = document.querySelector(".popular-list");
    
      if (popular) {
        popular.style.maxHeight = `${popular.scrollHeight}px`;
      }
    };

    const closePopularList = () => {
      const popular = document.querySelector(".popular-list");
    
      if (popular) {
        popular.style.maxHeight = `2rem`;
      }
    };

    const openNewList = () => {
      const newList = document.querySelector(".new-list");
    
      if (newList) {
        newList.style.maxHeight = `${newList.scrollHeight}px`;
      }
    };

    const closeNewList = () => {
      const newList = document.querySelector(".new-list");
    
      if (newList) {
        newList.style.maxHeight = `2rem`;
      }
    };

    const openOtherList = () => {
      const other = document.querySelector(".other-list");
    
      if (other) {
        other.style.maxHeight = `${other.scrollHeight}px`;
      }
    };

    const closeOtherList = () => {
      const other = document.querySelector(".other-list");
    
      if (other) {
        other.style.maxHeight = `2rem`;
      }
    };


    const checkList = (index) => {
      if (openLists.includes(index)) {
        return true;
      } else {
        return false;
      }


    }

    const handleInputChange = (e) => { 
    
      const searchItem = e.target.value;
  
      setSearchItem(searchItem);
  
    }

    const checkSearch = (game) => {
      if (searchItem.length > 0) {
        return game.toLowerCase().includes(searchItem.toLowerCase());
      } else {
        return true;
      }
    }

    const popularGames = gamescategory.find(category => category.category === "Popular").games;

    const hasPopularGames = popularGames.some(game => checkSearch(game));


    const newGames = gamescategory.find(category => category.category === "New").games;

    const hasNewGames = newGames.some(game => checkSearch(game));


    const otherGames = gamescategory.find(category => category.category === "Other").games;

    const hasOtherGames = otherGames.some(game => checkSearch(game));



    return (
      <>
        <div onClick={() => { setMobileOpen(!mobileOpen); setStoreMobileOpen(false) }} className="mobile-nav-burger">
          <div className="nav-line"></div>
          <div className="nav-line"></div>
          <div className="nav-line"></div>
        </div>
        <div className={mobileOpen ? "mobile-nav-blur mobile-nav-blur-active" : "mobile-nav-blur"}>
          <div className={storeMobileOpen ? "mobile-nav mobile-nav-noborder" : "mobile-nav"}>
          <div onClick={() => setStoreMobileOpen(!storeMobileOpen)} className = " nav-item-mobile-store">
                <span>Store</span>
                <svg className={open ? "rotated-mobile" : "normal-mobile"} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="#dfe4e4" d="M12 14.975q-.2 0-.375-.062T11.3 14.7l-4.6-4.6q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l3.9 3.9l3.9-3.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-4.6 4.6q-.15.15-.325.213t-.375.062"/></svg>
          </div>
          <Link aria-label="Link to the status of our cheats" to="/status">
            <div onClick={() => { setMobileOpen(false); setStoreMobileOpen(false) }} className = "nav-item-mobile">
                <span>Status</span>
              </div>
          </Link>
          <Link aria-label="Link to our cheating blogs" to="/">
            <div onClick={() => { setMobileOpen(false); setStoreMobileOpen(false) }} className = "nav-item-mobile">
                <span>Blogs</span>
              </div>
            </Link>
            <Link aria-label="Link to our cheating forums" to="/">
              <div onClick={() => { setMobileOpen(false); setStoreMobileOpen(false) }} className = "nav-item-mobile">
                <span>Forums</span>
              </div>
            </Link>
            <Link aria-label="Join our discord" to="/">
            <div onClick={() => { setMobileOpen(false); setStoreMobileOpen(false) }} className = "nav-item-mobile">
                <span>Discord</span>
              </div>
            </Link>
            <Link aria-label="Join our Youtube" to="/">
            <div onClick={() => { setMobileOpen(false); setStoreMobileOpen(false) }} className = "nav-item-mobile">
                <span>Youtube</span>
              </div>
            </Link>
            <Link aria-label="Join our Telegram" to="/">
              <div onClick={() => { setMobileOpen(false); setStoreMobileOpen(false) }} className = "nav-item-mobile">
                <span>Telegram</span>
              </div>
            </Link>
            <Link aria-label="Join our Tiktok" to="/">
            <div onClick={() => { setMobileOpen(false); setStoreMobileOpen(false) }} className = "nav-item-mobile">
                <span>TikTok</span>
              </div>
            </Link>
          </div>
          <div className={storeMobileOpen ? "mobile-products mobile-products-active " : "mobile-products"}>
            <div className = "mobile-product-games">
                {gamesdata.map((game) => {
                  return (
                    <Link aria-label={`Link to ${game} cheats`} to={`/games/${game}/`}>
                    <p onClick={() => { setMobileOpen(false); setStoreMobileOpen(false) }} key={game}>
                      {game} Cheats
                    </p>
                    </Link>
                  )
                })}
            </div>
          </div>
        </div>


        <nav className="nav" ref={navbarRef}>
          <div className="navbar">
            <Link aria-label="Link to our home page"to="/">
            <div onClick={() => setOpen(false)} className = "nav-item nav-title">
                <img alt="Hoodcheats logo" src={hood}></img>
                <span>HOODCHEATS</span>
              </div>
            </Link>
              <div onClick={() => setOpen(!open)}className = "nav-item">
                <span>Store</span>
                <svg className={open ? "rotated" : "normal"} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="#dfe4e4" d="M12 14.975q-.2 0-.375-.062T11.3 14.7l-4.6-4.6q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l3.9 3.9l3.9-3.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-4.6 4.6q-.15.15-.325.213t-.375.062"/></svg>
              </div>
            <Link aria-label="Link to the status of our cheats" to="/status">
            <div onClick={() => setOpen(false)} className = "nav-item">
                <span>Status</span>
              </div>
            </Link>
            
            <Link aria-label="Link to our cheating blogs" to="/">
            <div onClick={() => setOpen(false)} className = "nav-item">
                <span>Blogs</span>
              </div>
            </Link>
            <Link aria-label="Link to our cheating forums" to="/">
              <div onClick={() => setOpen(false)} className = "nav-item">
                <span>Forums</span>
              </div>
            </Link>
            <Link aria-label="Link to our support page" to="/">
            <div onClick={() => setOpen(false)} className = "nav-item">
                <span>Support</span>
              </div>
            </Link>
              <div className = "contacts">
                <Link aria-label="Join our Discord" to="/"><svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24"><path fill="#dfe4e4" d="M19.27 5.33C17.94 4.71 16.5 4.26 15 4a.09.09 0 0 0-.07.03c-.18.33-.39.76-.53 1.09a16.09 16.09 0 0 0-4.8 0c-.14-.34-.35-.76-.54-1.09c-.01-.02-.04-.03-.07-.03c-1.5.26-2.93.71-4.27 1.33c-.01 0-.02.01-.03.02c-2.72 4.07-3.47 8.03-3.1 11.95c0 .02.01.04.03.05c1.8 1.32 3.53 2.12 5.24 2.65c.03.01.06 0 .07-.02c.4-.55.76-1.13 1.07-1.74c.02-.04 0-.08-.04-.09c-.57-.22-1.11-.48-1.64-.78c-.04-.02-.04-.08-.01-.11c.11-.08.22-.17.33-.25c.02-.02.05-.02.07-.01c3.44 1.57 7.15 1.57 10.55 0c.02-.01.05-.01.07.01c.11.09.22.17.33.26c.04.03.04.09-.01.11c-.52.31-1.07.56-1.64.78c-.04.01-.05.06-.04.09c.32.61.68 1.19 1.07 1.74c.03.01.06.02.09.01c1.72-.53 3.45-1.33 5.25-2.65c.02-.01.03-.03.03-.05c.44-4.53-.73-8.46-3.1-11.95c-.01-.01-.02-.02-.04-.02zM8.52 14.91c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.84 2.12-1.89 2.12zm6.97 0c-1.03 0-1.89-.95-1.89-2.12s.84-2.12 1.89-2.12c1.06 0 1.9.96 1.89 2.12c0 1.17-.83 2.12-1.89 2.12z"/></svg></Link>
                <Link aria-label="Join our Telegram" to="/"><svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24"><path d="M9.78 18.65l.28-4.23l7.68-6.92c.34-.31-.07-.46-.52-.19L7.74 13.3L3.64 12c-.88-.25-.89-.86.2-1.3l15.97-6.16c.73-.33 1.43.18 1.15 1.3l-2.72 12.81c-.19.91-.74 1.13-1.5.71L12.6 16.3l-1.99 1.93c-.23.23-.42.42-.83.42z" fill="#dfe4e4"/></svg></Link>
                <Link aria-label="Join our Tiktok" to="/"><svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24"><path fill="#dfe4e4" d="M16.6 5.82s.51.5 0 0A4.278 4.278 0 0 1 15.54 3h-3.09v12.4a2.592 2.592 0 0 1-2.59 2.5c-1.42 0-2.6-1.16-2.6-2.6c0-1.72 1.66-3.01 3.37-2.48V9.66c-3.45-.46-6.47 2.22-6.47 5.64c0 3.33 2.76 5.7 5.69 5.7c3.14 0 5.69-2.55 5.69-5.7V9.01a7.35 7.35 0 0 0 4.3 1.38V7.3s-1.88.09-3.24-1.48z"/></svg></Link>
                <Link aria-label="Join our Youtube" to="/"><svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24"><path fill="#dfe4e4" d="m10 15l5.19-3L10 9v6m11.56-7.83c.13.47.22 1.1.28 1.9c.07.8.1 1.49.1 2.09L22 12c0 2.19-.16 3.8-.44 4.83c-.25.9-.83 1.48-1.73 1.73c-.47.13-1.33.22-2.65.28c-1.3.07-2.49.1-3.59.1L12 19c-4.19 0-6.8-.16-7.83-.44c-.9-.25-1.48-.83-1.73-1.73c-.13-.47-.22-1.1-.28-1.9c-.07-.8-.1-1.49-.1-2.09L2 12c0-2.19.16-3.8.44-4.83c.25-.9.83-1.48 1.73-1.73c.47-.13 1.33-.22 2.65-.28c1.3-.07 2.49-.1 3.59-.1L12 5c4.19 0 6.8.16 7.83.44c.9.25 1.48.83 1.73 1.73Z"/></svg></Link>
              </div>
          </div>
          <div className = "products">
            <div className = "product-games">
              <Link aria-label="Link to all our cheats" onClick={() => setOpen(false)} to="/games">
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24"><path fill="currentColor" d="M5 11q-.402 0-.701-.299Q4 10.4 4 10V5q0-.402.299-.701Q4.599 4 5 4h5q.402 0 .701.299q.299.3.299.701v5q0 .402-.299.701Q10.4 11 10 11zm0 9q-.402 0-.701-.299Q4 19.401 4 19v-5q0-.402.299-.701Q4.599 13 5 13h5q.402 0 .701.299q.299.3.299.701v5q0 .402-.299.701Q10.4 20 10 20zm9-9q-.402 0-.701-.299Q13 10.4 13 10V5q0-.402.299-.701Q13.6 4 14 4h5q.402 0 .701.299q.299.3.299.701v5q0 .402-.299.701q-.3.299-.701.299zm0 9q-.402 0-.701-.299Q13 19.401 13 19v-5q0-.402.299-.701Q13.6 13 14 13h5q.402 0 .701.299q.299.3.299.701v5q0 .402-.299.701q-.3.299-.701.299zM5 10h5V5H5zm9 0h5V5h-5zm0 9h5v-5h-5zm-9 0h5v-5H5zm5-9"/></svg>
              <p className="product-all">All Games</p>
              </Link>
              <div onClick={() => openAllLists()}className="nav-searchbar">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m19.6 21l-6.3-6.3q-.75.6-1.725.95T9.5 16q-2.725 0-4.612-1.888T3 9.5q0-2.725 1.888-4.612T9.5 3q2.725 0 4.613 1.888T16 9.5q0 1.1-.35 2.075T14.7 13.3l6.3 6.3zM9.5 14q1.875 0 3.188-1.312T14 9.5q0-1.875-1.312-3.187T9.5 5Q7.625 5 6.313 6.313T5 9.5q0 1.875 1.313 3.188T9.5 14"/></svg>
                <input type="text" value={searchItem} onChange={handleInputChange} placeholder='Search Games' />
              </div>
              <div style={{ display: hasPopularGames ? 'flex' : 'none' }} className={checkList(1) ? "product-list product-list-open popular-list" : "product-list product-list-closed  popular-list"}>
                <div  onClick={() => toggleList(1)}  className="product-list-title">
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 256 256"><path fill="currentColor" d="M181.92 153A55.58 55.58 0 0 1 137 197.92a7 7 0 0 1-1 .08a6 6 0 0 1-1-11.92c17.38-2.92 32.13-17.68 35.08-35.08a6 6 0 1 1 11.84 2m32.08-9a86 86 0 0 1-172 0c0-27.47 10.85-55.61 32.25-83.64a6 6 0 0 1 9-.67l26.34 25.56l23.09-63.31a6 6 0 0 1 9.47-2.56C163.72 37.33 214 85.4 214 144m-12 0c0-48.4-38.65-89.84-61.07-109.8l-23.29 63.86a6 6 0 0 1-9.82 2.25l-28-27.22C62.67 97.13 54 121 54 144a74 74 0 0 0 148 0"/></svg>

                  <p>Popular Games</p>
                  <div className="plus-icon">
                    <div className="plus"></div>
                    <div className="minus"></div>
                  </div>
                </div>
                <div className="product-list-games" >
                  {gamescategory.map((gameslist) => {
                    return (
                      gameslist.category === "Popular" ? gameslist.games.map((game) => {
                        return (
                          checkSearch(game) ? (
                          <p className = {game === selectedGame ? "selected-game" : ""} key={game} onClick={() => handleGameClick(game)}>
                            {game} Cheats
                          </p>
                          ) : <></>
                        )
                      }) : <></>
                    )
                  })}
                </div>

              </div>

              <div style={{ display: hasNewGames ? 'flex' : 'none' }} className={checkList(2) ? "product-list product-list-open new-list" : "product-list product-list-closed new-list"}>
                <div onClick={() => toggleList(2)} className="product-list-title">
                    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 256 256"><path fill="currentColor" d="m196.89 130.94l-51.65-19a2 2 0 0 1-1.15-1.14l-19-51.66a13.92 13.92 0 0 0-26.12 0l-19 51.65a2 2 0 0 1-1.14 1.15l-51.66 19a13.92 13.92 0 0 0 0 26.12l51.65 19a2 2 0 0 1 1.15 1.14l19 51.66a13.92 13.92 0 0 0 26.12 0l19-51.65a2 2 0 0 1 1.14-1.15l51.66-19a13.92 13.92 0 0 0 0-26.12m-4.15 14.86l-51.66 19a13.94 13.94 0 0 0-8.25 8.26l-19 51.65a1.92 1.92 0 0 1-3.6 0l-19-51.66a14 14 0 0 0-8.25-8.25l-51.65-19a1.92 1.92 0 0 1 0-3.6l51.66-19a13.94 13.94 0 0 0 8.25-8.26l19-51.65a1.92 1.92 0 0 1 3.6 0l19 51.66a13.94 13.94 0 0 0 8.26 8.25l51.65 19a1.92 1.92 0 0 1 0 3.6ZM146 40a6 6 0 0 1 6-6h18V16a6 6 0 0 1 12 0v18h18a6 6 0 0 1 0 12h-18v18a6 6 0 0 1-12 0V46h-18a6 6 0 0 1-6-6m100 48a6 6 0 0 1-6 6h-10v10a6 6 0 0 1-12 0V94h-10a6 6 0 0 1 0-12h10V72a6 6 0 0 1 12 0v10h10a6 6 0 0 1 6 6"/></svg>
                    <p>New Games</p>
                    <div className="plus-icon">
                    <div className="plus"></div>
                    <div className="minus"></div>
                  </div>
                </div>
                <div className="product-list-games">
                {gamescategory.map((gameslist) => {
                    return (
                      gameslist.category === "New" ? gameslist.games.map((game) => {
                        return (
                          checkSearch(game) ? (
                          <p className = {game === selectedGame ? "selected-game" : ""} key={game} onClick={() => handleGameClick(game)}>
                            {game} Cheats
                          </p>
                          ) : <></>
                        )
                      }) : <></>
                    )
                  })}
                </div>

              </div>

              <div style={{ display: hasOtherGames ? 'flex' : 'none' }} className={checkList(3) ? "product-list product-list-open other-list" : "product-list product-list-closed  other-list"}>
                <div  onClick={() => toggleList(3)} className="product-list-title">
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M448 341.37V170.61A32 32 0 0 0 432.11 143l-152-88.46a47.94 47.94 0 0 0-48.24 0L79.89 143A32 32 0 0 0 64 170.61v170.76A32 32 0 0 0 79.89 369l152 88.46a48 48 0 0 0 48.24 0l152-88.46A32 32 0 0 0 448 341.37"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="m69 153.99l187 110l187-110m-187 310v-200"/></svg>
                    <p>Other Games</p>
                    <div className="plus-icon">
                    <div className="plus"></div>
                    <div className="minus"></div>
                  </div>
                </div>
                <div className="product-list-games">
                {gamescategory.map((gameslist) => {
                    return (
                      gameslist.category === "Other" ? gameslist.games.map((game) => {
                        return (
                          checkSearch(game) ? (
                          <p className = {game === selectedGame ? "selected-game" : ""} key={game} onClick={() => handleGameClick(game)}>
                            {game} Cheats
                          </p>
                          ) : <></>
                        )
                      }) : <></>
                    )
                  })}
                </div>

              </div>
              

            </div>

            {selectedGame !== null ? (
                  <div className="product-cheats">
                    <div className="product-cheats-title">
                      <h3>{selectedGame} Hacks</h3>
                      <p>
                        Our wide selection of top tier {selectedGame} cheats carefully selected to offer you an incredible advantage in {selectedGame}
                      </p>
                    </div>
                    <div className="product-cheats-list">
                      {cheats.filter((cheat) => cheat.game === selectedGame).map((filteredCheat, index) => ( 
                        
                        <Link aria-label={`Link to ${selectedGame} ${filteredCheat.name}`} to={`/games/${selectedGame}/${filteredCheat.name}`}><li onClick={() => setOpen(false)} key={index}>{filteredCheat.name}</li></Link>
                      ))}
                    </div>
                    <Link aria-label={`Link to ${selectedGame}`} to={`/games/${selectedGame}`}><button className="product-button" onClick={() => setOpen(false)}>VIEW GAME</button></Link>
                  </div>
              ) : (
                <div className="product-cheats-empty">
                  <p className="pick">
                    No Game Selected
                    </p>
                    <svg className="pick-background" xmlns="http://www.w3.org/2000/svg" width="13rem" height="13rem" viewBox="0 0 512 512"><path fill="currentColor" d="M483.13 245.38C461.92 149.49 430 98.31 382.65 84.33A107.13 107.13 0 0 0 352 80c-13.71 0-25.65 3.34-38.28 6.88C298.5 91.15 281.21 96 256 96s-42.51-4.84-57.76-9.11C185.6 83.34 173.67 80 160 80a115.74 115.74 0 0 0-31.73 4.32c-47.1 13.92-79 65.08-100.52 161C4.61 348.54 16 413.71 59.69 428.83a56.62 56.62 0 0 0 18.64 3.22c29.93 0 53.93-24.93 70.33-45.34c18.53-23.1 40.22-34.82 107.34-34.82c59.95 0 84.76 8.13 106.19 34.82c13.47 16.78 26.2 28.52 38.9 35.91c16.89 9.82 33.77 12 50.16 6.37c25.82-8.81 40.62-32.1 44-69.24c2.57-28.48-1.39-65.89-12.12-114.37M208 240h-32v32a16 16 0 0 1-32 0v-32h-32a16 16 0 0 1 0-32h32v-32a16 16 0 0 1 32 0v32h32a16 16 0 0 1 0 32m84 4a20 20 0 1 1 20-20a20 20 0 0 1-20 20m44 44a20 20 0 1 1 20-19.95A20 20 0 0 1 336 288m0-88a20 20 0 1 1 20-20a20 20 0 0 1-20 20m44 44a20 20 0 1 1 20-20a20 20 0 0 1-20 20"/></svg>
                </div>
              )}

          </div>

        </nav>
        </>
    )
}