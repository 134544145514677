import React from "react";
import { gamesdata } from '../components/cheats-data';
import { cheats } from '../components/cheats-data';

import { motion } from 'framer-motion'

export const Sitemap = () => {

    return (
        <motion.main className='sitemap'
        initial={{width: 0, opacity: 0}}
        animate={{width: "100%", opacity: 1}}
        exit={{ x: window.innerWidth, opacity: 0, transition: {duration: 0.1}}}
        >
            <header>Sitemap</header>

            <div className='sitemap-lists'>
            {gamesdata.map((game) => {
                return (
                    <div className='sitemap-list'>
                        <h1>{game.toUpperCase()} PRODUCTS</h1>
                        
                        <ul>
                        {cheats.map((cheat) => {
                            return (
                                cheat.game === game ? (
                                    <li>PRODUCT</li>
                                ) : (
                                    <></>
                                )
                            );
                        })}
                        </ul>
                    </div>
                );
            })}
            </div>
        </motion.main>
    );
}