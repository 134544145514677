import React, {useState, useEffect} from "react";
import rust from '../images/backgrounds/rustimage.webp'
import background from '../images/backgrounds/tarkovbackground.webp'

import { motion } from 'framer-motion'


export const About = () => {

    
  const [cardOne, setCardOne] = useState(0);
  const [activeCard, setActiveCard] = useState(0);
  const [cardTwo, setCardTwo] = useState(0);
  const [cardThree, setCardThree] = useState(0);


    
  useEffect(() => {
    const historyCardOne = document.querySelector('.one');
    const historyCardTwo  = document.querySelector('.two');
    const historyCardThree = document.querySelector('.three');



    setCardOne((historyCardOne.getBoundingClientRect().top + window.scrollY) - historyCardOne.getBoundingClientRect().height / 2);
    setCardTwo((historyCardTwo.getBoundingClientRect().top + window.scrollY) - historyCardTwo.getBoundingClientRect().height / 2);
    setCardThree((historyCardThree.getBoundingClientRect().top + window.scrollY) - historyCardThree.getBoundingClientRect().height / 2);


    const handleScroll = () => {
      // Update the margin-top value based on the scroll position
      const scrollY = window.scrollY;



      // Check if scroll position is near the menu location
        if (Math.abs(scrollY - cardOne) < 350) {

            setActiveCard(0);
        } else if (Math.abs(scrollY - cardTwo) < 350) {
            setActiveCard(1);
        } else if (Math.abs(scrollY - cardThree) < 350) {
            setActiveCard(2);
        }
        
        
      }



    // Add a scroll event listener
    window.addEventListener('scroll', handleScroll);
    window.addEventListener("load", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener("load", handleScroll);
    };
  })


    return (
        <motion.main className='about-main'
        initial={{width: 0, opacity: 0}}
        animate={{width: "100%", opacity: 1}}
        exit={{ x: window.innerWidth, opacity: 0, transition: {duration: 0.1}}}
        >

            <header className='about-header'>
                <h1>About Us</h1>
                <img alt="Hoodcheats cheating cheat hacking hacks" src={background}></img>
            </header>

            <section className='about-history'>
                <header>Our History</header>

                <div className={activeCard === 0 ? "about-history-frame about-history-frame-active one" : "about-history-frame one"}>
                    <img alt="Hoodcheats cheating cheat hacking hacks" src={rust}></img>
                    <h1>2021</h1>
                    <div className='history-text'>
                        <h2>Humble Beginnings</h2>
                        <p>Started during 2021, we as cheaters wanted a more robust, transparent, and professional market to find high quality cheats. So we decided to just do it ourselves, and thus Hoodcheats was born.</p>
                    </div>
                </div>

                <div className={activeCard === 1 ? "about-history-frame about-history-frame-active two" : "about-history-frame two"}>
                    <img alt="Hoodcheats cheating cheat hacking hacks" src={rust}></img>
                    <h1 className='history-right'>2023</h1>
                    <div className='history-text history-right'>
                        <h2>Dedication</h2>
                        <p>Being a small team, we used our skills and dedication to meticulously design Hoodcheats with cheaters as our priority. Our slick design, ease of access, and custom payment system were all designed to help provide the best experience.</p>
                    </div>
                </div>

                <div className={activeCard === 2 ? "about-history-frame about-history-frame-active three" : "about-history-frame three"}>
                    <img alt="Hoodcheats cheating cheat hacking hacks" src={rust}></img>
                    <h1>2024</h1>
                    <div className='history-text'>
                        <h2>Release</h2>
                        <p>Finally after years of researching, designing, and programming. Hoodcheats was released and marketed to the cheater community. We created our dream and a market that we hope you will love.</p>
                    </div>
                </div>


            </section>

            <section className='about-goals'>
                <header>Our 2025 Goals</header>

                <div className='goals-container'>
                    <div className='goal'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 16 16"><path fill="currentColor" d="M8 2.002a1.998 1.998 0 1 0 0 3.996a1.998 1.998 0 0 0 0-3.996M12.5 3a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3m-9 0a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3M5 7.993A1 1 0 0 1 6 7h4a1 1 0 0 1 1 1v3a3.02 3.02 0 0 1-.146.927A3.001 3.001 0 0 1 5 11V8zM4 8c0-.365.097-.706.268-1H2a1 1 0 0 0-1 1v2.5a2.5 2.5 0 0 0 3.436 2.319A3.983 3.983 0 0 1 4 10.999zm8 0v3c0 .655-.157 1.273-.436 1.819A2.5 2.5 0 0 0 15 10.5V8a1 1 0 0 0-1-1h-2.268c.17.294.268.635.268 1"/></svg>
                        <div className='goal-text'>
                            <h1>+5000 Members</h1>
                            <p>We hope to gain 5000 new members to use our market.</p>
                        </div>
                    </div>

                    <div className='goal'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 24 24"><path fill="currentColor" d="m3.5 18.49l6-6.01l4 4L22 6.92l-1.41-1.41l-7.09 7.97l-4-4L2 16.99z"/></svg>
                        <div className='goal-text'>
                            <h1>+15000 Sales</h1>
                            <p>We hope to make over 15,000 sales on our products.</p>
                        </div>
                    </div>

                    <div className='goal'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 512 512"><path fill="currentColor" d="M256 73.32L74.6 155.1l82.3 37.1l173.2-85.5zm95.4 42.98l-173.2 85.5l77.8 35.1l181.4-81.8zM61.7 169v182L247 434.6v-182zm388.6 0L265 252.6v182L450.3 351z"/></svg>
                        <div className='goal-text'>
                            <h1>10 New Products</h1>
                            <p>We hope to expand are range of products to more than just cheats, but products like VPN's, Spoofers, and Flash drives.</p>
                        </div>
                    </div>

                </div>
            </section>


        </motion.main>
    );
}