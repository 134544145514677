import React, {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { cheats } from "../components/cheats-data";
import { gamesdata } from "../components/cheats-data";
import rust from '../images/backgrounds/rustimage.webp'
import apex from '../images/backgrounds/apex.webp'
import logo from '../images/logo.webp'

import { motion } from 'framer-motion'

export const Product = () => {

  
  const { gameUrl } = useParams();
  const { cheatUrl } = useParams();


  const [openCheats, setOpenCheats] = useState(true);
  const [dragStartX, setDragStartX] = useState(0);
  const [initialScrollLeft, setInitialScrollLeft] = useState(0);
  const [isTagDragging, setIsTagDragging] = useState(false);
  const purchaseWindowRef = useRef(null);
  const productRef = useRef(null);

  const [activeTab, setActiveTab] = useState("Features");


  useEffect(() => {

    const handleScroll = () => {
      const purchaseWindow = purchaseWindowRef.current;
      const productWindow = productRef.current
      const productHeight = productWindow.clientHeight;
      const windowHeight = purchaseWindow.clientHeight;
      console.log("max", productHeight - windowHeight);
      console.log("scroll", window.scrollY)
      const maxScrollPosition = productHeight - windowHeight - 44;

      // Check if the scroll position exceeds the maximum allowed position
      if (window.scrollY > maxScrollPosition) {
        purchaseWindow.style.top = maxScrollPosition + 'px'; // Set the top position
      } else {
        purchaseWindow.style.top = window.scrollY + 'px';
      }
    };

    handleScroll();

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);



    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);

    };
  },);





  let doesExist = false;
  let cheatSelected;

  
  if (!gameUrl || !cheatUrl) {
    return null;
  } else {
    for (const game of gamesdata) {
      if (gameUrl === game) {
        for (const cheat of cheats) {
          if (gameUrl === cheat.game && cheatUrl === cheat.name) {
            doesExist = true;
            cheatSelected = cheat;
          }
        }
      }
    }
  }






  const handleDragStart = (e) => {

    e.preventDefault();
    setDragStartX(e.clientX);
    setInitialScrollLeft(e.target.scrollLeft);
    setIsTagDragging(true);
  };

  const handleDrag = (e) => {
    if (!isTagDragging) return;

    const dragDistance = e.clientX - dragStartX;
    e.target.scrollLeft = initialScrollLeft - dragDistance;
  };

  const handleDragEnd = () => {
    setIsTagDragging(false);
  };




  if(doesExist) {

    return (
      <motion.main className='cheat-product-page'
        initial={{width: 0, opacity: 0}}
        animate={{width: "100%", opacity: 1}}
        exit={{ x: window.innerWidth, opacity: 0, transition: {duration: 0.1}}}
        >
        <div className="breadcrumbs">
          <p><Link aria-label="Link to all cheats" to={`/games`}>All Games</Link></p>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="#dfe4e4" d="M12 14.975q-.2 0-.375-.062T11.3 14.7l-4.6-4.6q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l3.9 3.9l3.9-3.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-4.6 4.6q-.15.15-.325.213t-.375.062"/></svg>
          <p><Link aria-label={`Link to ${cheatSelected.game} ${cheatSelected.name}`} to={`/games/${cheatSelected.game}`}>{cheatSelected.game}</Link></p>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="#dfe4e4" d="M12 14.975q-.2 0-.375-.062T11.3 14.7l-4.6-4.6q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l3.9 3.9l3.9-3.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7l-4.6 4.6q-.15.15-.325.213t-.375.062"/></svg>
          <p>{cheatSelected.name}</p>
        </div>

        <div className='cheat-product-container'>
          <div className='cheat-product-tags'>
              <div className='product-tag'></div>
          </div>

          <div ref={productRef} className='product-container'>
            <div className='product-main-col'>
              <div className='product-info-container'>
                <img src={apex} alt='alt here' />
                <div className='product-info'>
                  <div>
                  <h1>Quantum Cheats</h1>
                  <span><h2>Status:</h2><h3>data</h3></span>
                  <span><h2>data since:</h2><h3>data</h3></span>
                  <span><h2>Last Updated:</h2><h3>data</h3></span>
                  <span><h2>Risk Percentage:</h2><h3>data</h3></span>
                  </div>
                  <div className='product-description'>
                    <p>Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description Description </p>
                  </div>
                </div>
              </div>
              <div className='product-tabs-container'>
                <div className='product-tabs'>
                  <h2 className={activeTab === "Features" && 'active-tab'} onClick={()=>setActiveTab("Features")}>Features</h2>
                  <h2 className={activeTab === "Requirements" && 'active-tab'} onClick={()=>setActiveTab("Requirements")}>Requirements</h2>
                  <h2 className={activeTab === "Gameplay" && 'active-tab'} onClick={()=>setActiveTab("Gameplay")}>Gameplay</h2>
                  <h2 className={activeTab === "Menu" && 'active-tab'} onClick={()=>setActiveTab("Menu")}>Menu</h2>
                  <h2 className={activeTab === "Instructions" && 'active-tab'} onClick={()=>setActiveTab("Instructions")}>Instructions</h2>
                </div>
                <div className='product-tab-container'>
                    <div className={activeTab === "Features" ? 'product-tab-active features-tab' : 'product-tab' }>
                      
                      <ul className='feature-list'>
                        <li className='feature-list-title'><h2>Aimbot</h2></li>
                        <li><p>Feature</p></li>
                        <li><p>Feature</p></li>
                        <li><p>Feature</p></li>
                        <li><p>Feature</p></li>
                        <li><p>Feature</p></li>
                        <li><p>Feature</p></li>
                        <li><p>Feature</p></li>
                        <li><p>Feature</p></li>
                        <li><p>Feature</p></li>
                        <li><p>Feature</p></li>
                        <li><p>Feature</p></li>
                        <li><p>Feature</p></li>
                        <li><p>Feature</p></li>
                        <li><p>Feature</p></li>
                        <li><p>Feature</p></li>
                        <li><p>Feature</p></li>
                        <li><p>Feature</p></li>
                        <li><p>Feature</p></li>
                        <li><p>Feature</p></li>
                      </ul>

                    </div>
                    <div className={activeTab === "Requirements" ? 'product-tab-active require-tab': 'product-tab require-tab'}>
                      <div className='require-item'>
                         <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 448 512"><path fill="currentColor" d="m0 93.7l183.6-25.3v177.4H0zm0 324.6l183.6 25.3V268.4H0zm203.8 28L448 480V268.4H203.8zm0-380.6v180.1H448V32z"/></svg>
                         <span>
                          <h2 className='require-item-title'>Supported OS</h2>
                          <h2>Data</h2>
                         </span>
                      </div>
                      <div className='require-item'>
                         <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 20 20"><g fill="currentColor"><path d="M14 6H6v8h8z"/><path fill-rule="evenodd" d="M9.25 3V1.75a.75.75 0 0 1 1.5 0V3h1.5V1.75a.75.75 0 0 1 1.5 0V3h.5A2.75 2.75 0 0 1 17 5.75v.5h1.25a.75.75 0 0 1 0 1.5H17v1.5h1.25a.75.75 0 0 1 0 1.5H17v1.5h1.25a.75.75 0 0 1 0 1.5H17v.5A2.75 2.75 0 0 1 14.25 17h-.5v1.25a.75.75 0 0 1-1.5 0V17h-1.5v1.25a.75.75 0 0 1-1.5 0V17h-1.5v1.25a.75.75 0 0 1-1.5 0V17h-.5A2.75 2.75 0 0 1 3 14.25v-.5H1.75a.75.75 0 0 1 0-1.5H3v-1.5H1.75a.75.75 0 0 1 0-1.5H3v-1.5H1.75a.75.75 0 0 1 0-1.5H3v-.5A2.75 2.75 0 0 1 5.75 3h.5V1.75a.75.75 0 0 1 1.5 0V3zM4.5 5.75c0-.69.56-1.25 1.25-1.25h8.5c.69 0 1.25.56 1.25 1.25v8.5c0 .69-.56 1.25-1.25 1.25h-8.5c-.69 0-1.25-.56-1.25-1.25z" clip-rule="evenodd"/></g></svg>
                         <span>
                          <h2 className='require-item-title'>Supported CPU's</h2>
                          <h2>Data</h2>
                         </span>
                      </div>
                      <div className='require-item'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 56 56"><path fill="currentColor" fill-rule="evenodd" d="M22.792 51.433a23.847 23.847 0 0 1-6.878-2.694l.124-2.425c.169-3.307-2.384-5.85-5.682-5.682l-2.68.137a23.871 23.871 0 0 1-2.854-6.517l2.104-1.9c2.458-2.218 2.452-5.822 0-8.035l-2.243-2.025A23.852 23.852 0 0 1 7.28 15.88l3.076.157c3.307.169 5.85-2.384 5.682-5.682L15.88 7.28a23.852 23.852 0 0 1 6.41-2.597l2.026 2.243c2.22 2.458 5.823 2.452 8.036 0l1.9-2.104a23.871 23.871 0 0 1 6.516 2.853l-.137 2.68c-.169 3.308 2.384 5.851 5.682 5.683l2.425-.124a23.847 23.847 0 0 1 2.694 6.878l-1.69 1.525c-2.457 2.22-2.45 5.823 0 8.036l1.561 1.408a23.864 23.864 0 0 1-2.958 6.975l-2.032-.104c-3.307-.169-5.85 2.384-5.682 5.682l.104 2.032a23.864 23.864 0 0 1-6.975 2.958l-1.408-1.56c-2.22-2.458-5.823-2.452-8.036 0zM28 38c5.523 0 10-4.477 10-10s-4.477-10-10-10s-10 4.477-10 10s4.477 10 10 10"/></svg>
                          <span>
                          <h2 className='require-item-title'>Important Note</h2>
                          <h2>Data</h2>
                         </span>
                      </div>
                    </div>

                    <div className={activeTab === "Gameplay" ? 'product-tab-active game-tab' : 'product-tab game-tab'}>
                      <div className='game-media'><iframe width="560" height="315" src="https://www.youtube.com/embed/6c1BThu95d8?si=_bs-laAjqlVVs-cD" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></div>
                      <div className='game-media-inactive'><iframe width="560" height="315" src="https://www.youtube.com/embed/10LSq_J5ol4?si=bDdMl8Ez29osUfEM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></div>

                      <div className='game-nav'>
                        <div className='game-nav-dot dot-active' />
                        <div className='game-nav-dot' />
                      </div>
                    </div>  

                    <div className={activeTab === "Menu" ? 'product-tab-active menu-tab' : 'product-tab menu-tab'}>
                      <div className='menu-item'>

                      </div>
                      <div className='menu-item'>
                        
                      </div>
                      <div className='menu-item'>
                        
                      </div>
                    </div>

                    <div className={activeTab === "Instructions" ? 'product-tab-active instruction-tab' : 'product-tab instruction-tab'}>
                      <div className='instruction-item'>
                        <h2>Instruction Step 1</h2>
                        <h3>Description Description Description Description Description Description Description </h3>
                      </div>
                      <div className='instruction-item'>
                        <h2>Instruction Step 2</h2>
                        <h3>Description Description Description Description Description Description Description </h3>
                      </div>
                      <div className='instruction-item'>
                        <h2>Instruction Step 3</h2>
                        <h3>Description Description Description Description Description Description Description </h3>
                      </div>
                      <div className='instruction-item'>
                        <h2>Instruction Step 4</h2>
                        <h3>Description Description Description Description Description Description Description </h3>
                      </div>
                    </div>
                </div>
              </div>
            </div>
            <div className='product-secondary-col'>
              <div className='product-checkout-container'>
                <div ref={purchaseWindowRef} className='product-checkout'>
                  <h2>Checkout</h2>
                  <div className='checkout-item'>
                    <div className='checkout-radio'/>
                    <div className='checkout-item-info'>
                      <h2>Day</h2>
                      <h2>$data</h2>
                    </div>
                  </div>
                  <div className='checkout-item checkout-item-active'>
                    <div className='checkout-radio'/>
                    <div className='checkout-item-info'>
                      <h2>Week</h2>
                      <h2>$data</h2>
                    </div>
                  </div>

                  <div className='checkout-purchase'><h1>PURCHASE</h1></div>
                </div>
              </div>
            </div>
          </div>

          <div className='product-checkout-container-mobile'>
              <div className='product-checkout'>
                  <h2>Checkout</h2>
                  <div className='checkout-item'>
                    <div className='checkout-radio'/>
                    <div className='checkout-item-info'>
                      <h2>Day</h2>
                      <h2>$data</h2>
                    </div>
                  </div>
                  <div className='checkout-item checkout-item-active'>
                    <div className='checkout-radio'/>
                    <div className='checkout-item-info'>
                      <h2>Week</h2>
                      <h2>$data</h2>
                    </div>
                  </div>

                  <div className='checkout-purchase'><h1>PURCHASE</h1></div>
                </div>
              </div>



          <div className={openCheats ? "other-cheats other-open" : "other-cheats  other-closed"}>
            <div onClick={() => setOpenCheats(!openCheats)}className='other-header'>
            <p className='other-title'>Other {gameUrl} Cheats</p>
            </div>
            <div className="other-cheats-row">
              {cheats.map((cheat) => {
                return cheat.game === cheatSelected.game ? (
                  <>
                    <div className="cheat-card">


                      <div draggable="false"  className="cheat-tags-slider-mask">
                      
                      
                      <div draggable="false" className="cheat-tags-slider">
                      <div className="cheat-tags" onMouseDown={handleDragStart} onMouseMove={handleDrag} onMouseUp={handleDragEnd} onMouseLeave={handleDragEnd}>
                        {cheat.genre.Tags.map((tag) => {
                          return <div className={"cheat-tag " + tag.toLowerCase()}>{tag.toUpperCase()}</div>
                        })}
                      </div>
                      </div>
                      </div>
                      <Link aria-label={`Link to ${cheatSelected.game} ${cheatSelected.name}`} to={`/games/${cheatSelected.game}/${cheatSelected.name}`}>
                      <div className="cheat-link-container">
                        <div className="cheat-image">
                          <img alt={`${cheat.game} ${cheat.name}`} draggable="false" src={rust}/>
                          <img alt={`${cheat.name} logo`}draggable="false" className="cheat-logo"src={logo}></img>
                        </div>
                        <div className="cheat-info">
                          <div cl assName="cheat-info-col">
                            <div className="cheat-name">
                              <h1>{cheat.name}</h1>
                            </div>
                            <div className="cheat-range">
                              <p>Starting at: <h3>${cheat.day}</h3></p>
                            </div>
                          </div>
                            <div className="cheat-stars">
                                <h3>4.9</h3>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2L9.19 8.63L2 9.24l5.46 4.73L5.82 21z"/></svg>
                            </div>
                        </div>
                      </div>
                      </Link>
                    </div>
                </>
                ) : (
                  <></>
                )
              })}
            </div>
          </div>

        </div>
        </motion.main>
    );
  } else {
    return (
      <div className='test'>
        404
      </div>
    );
  }

  
}