
export const Checkboxes = [
  {
    "genre": "Cheat Tags",
    "checkbox": [
      "Undetected",
      "Tested",
      "New",
      "Popular",
      "Sponsored"
    ]
  },
  {
    "genre": "Providers",
    "checkbox": [
      "Quantum Cheats",
      "Division",
      "Fluent.gg",
      "Cola",
      "Pixel Perfect"
    ]
  },
  {
    "genre": "Deals",
    "checkbox": [
      "Discounted",
      "Limited Time Deal",
    ]
  },
  
]