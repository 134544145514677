
import rust from '../images/backgrounds/rustimage.webp'




export const cheats = [
  {
    "name": "Quantum Cheats",
    "logo" : "logo",
    "day": 5,
    "week": 20,
    "month": 40,
    "image": "",
    "game": "Rust",
    "featuredVideo": "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr",
    "isUndetected": true,
    "isUpdating": false,
    "genre": {
      "Tags": ["Sponsored", "Undetected", "Tested", "Trending", "Popular"],
      "Providers": "Cheats.pro"
    },
    media: [
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
      { img: "image" },
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
    ],
    "description": "I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop",
    "menu" : [
    ],
    "features": [
      {
          "title": "AIMBOT",
          "items": [
              "Features",
              "Features",
              "Features",
            ]
      },
      {
        "title": "VISUALS",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "CONFIG",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "MISC",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      }
    ],
    "chams" : [
      //NO MORE THAN TWO
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
    ],
    "instructions" : [
      {
        "title": "Step #1",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #2",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #3",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #4",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
    ],
  },
  {
    "name": "Quantum Cheats",
    "logo" : "logo",
    "day": 5,
    "week": 20,
    "month": 40,
    "image": "",
    "game": "Rust",
    "featuredVideo": "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr",
    "isUndetected": true,
    "isUpdating": false,
    "genre": {
      "Tags": ["Sponsored", "Undetected", "Tested", "Trending", "Popular"],
      "Providers": "Cheats.pro"
    },
    media: [
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
      { img: "image" },
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
    ],
    "description": "I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop",
    "menu" : [
      // must have at minimum 5 or it breaks, im not fixing
      {img: rust},
      {img: rust},
      {img: rust},
      {img: rust},  
      {img: rust}, 
    ],
    "features": [
      {
          "title": "AIMBOT",
          "items": [
              "Features",
              "Features",
              "Features",
            ]
      },
      {
        "title": "VISUALS",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "CONFIG",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "MISC",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      }
    ],
    "chams" : [
      //NO MORE THAN TWO
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
    ],
    "instructions" : [
      {
        "title": "Step #1",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #2",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #3",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #4",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
    ],
  },
  {
    "name": "Quantum Cheats",
    "logo" : "logo",
    "day": 5,
    "week": 20,
    "month": 40,
    "image": "",
    "game": "Rust",
    "featuredVideo": "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr",
    "isUndetected": true,
    "isUpdating": false,
    "genre": {
      "Tags": ["Sponsored", "Undetected", "Tested", "Trending", "Popular"],
      "Providers": "Cheats.pro"
    },
    media: [
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
      { img: "image" },
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
    ],
    "description": "I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop",
    "menu" : [
      // must have at minimum 5 or it breaks, im not fixing
      {img: rust},
      {img: rust},
      {img: rust},
      {img: rust},  
      {img: rust}, 
    ],
    "features": [
      {
          "title": "AIMBOT",
          "items": [
              "Features",
              "Features",
              "Features",
            ]
      },
      {
        "title": "VISUALS",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "CONFIG",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "MISC",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      }
    ],
    "chams" : [
      //NO MORE THAN TWO
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
    ],
    "instructions" : [
      {
        "title": "Step #1",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #2",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #3",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #4",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
    ],
  },
  {
    "name": "Quantum Cheats",
    "logo" : "logo",
    "day": 5,
    "week": 20,
    "month": 40,
    "image": "",
    "game": "Rust",
    "featuredVideo": "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr",
    "isUndetected": true,
    "isUpdating": false,
    "genre": {
      "Tags": ["Sponsored", "Undetected", "Tested", "Trending", "Popular"],
      "Providers": "Cheats.pro"
    },
    media: [
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
      { img: "image" },
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
    ],
    "description": "I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop",
    "menu" : [
      // must have at minimum 5 or it breaks, im not fixing
      {img: rust},
      {img: rust},
      {img: rust},
      {img: rust},  
      {img: rust}, 
    ],
    "features": [
      {
          "title": "AIMBOT",
          "items": [
              "Features",
              "Features",
              "Features",
            ]
      },
      {
        "title": "VISUALS",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "CONFIG",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "MISC",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      }
    ],
    "chams" : [
      //NO MORE THAN TWO
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
    ],
    "instructions" : [
      {
        "title": "Step #1",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #2",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #3",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #4",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
    ],
  },
  
  {
    "name": "Quantum Cheats",
    "logo" : "logo",
    "day": 5,
    "week": 20,
    "month": 40,
    "image": "",
    "game": "Apex Legends",
    "featuredVideo": "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr",
    "isUndetected": true,
    "isUpdating": false,
    "genre": {
      "Tags": ["Sponsored", "Undetected", "Tested", "Trending", "Popular"],
      "Providers": "Cheats.pro"
    },
    media: [
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
      { img: "image" },
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
    ],
    "description": "I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop",
    "menu" : [
      // must have at minimum 5 or it breaks, im not fixing
      {img: rust},
      {img: rust},
      {img: rust},
      {img: rust},  
      {img: rust}, 
    ],
    "features": [
      {
          "title": "AIMBOT",
          "items": [
              "Features",
              "Features",
              "Features",
            ]
      },
      {
        "title": "VISUALS",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "CONFIG",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "MISC",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      }

    ],
    "chams" : [
      //NO MORE THAN TWO
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
    ],
    "instructions" : [
      {
        "title": "Step #1",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #2",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #3",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #4",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
    ],
  },
  {
    "name": "Quantum Cheats",
    "logo" : "logo",
    "day": 5,
    "week": 20,
    "month": 40,
    "image": "",
    "game": "Escape from Tarkov",
    "featuredVideo": "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr",
    "isUndetected": true,
    "isUpdating": false,
    "genre": {
      "Tags": ["Sponsored", "Undetected", "Tested", "Trending", "Popular"],
      "Providers": "Cheats.pro"
    },
    media: [
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
      { img: "image" },
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
    ],
    "description": "I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop",
    "menu" : [
      // must have at minimum 5 or it breaks, im not fixing
      {img: rust},
      {img: rust},
      {img: rust},
      {img: rust},  
      {img: rust}, 
    ],
    "features": [
      {
          "title": "AIMBOT",
          "items": [
              "Features",
              "Features",
              "Features",
            ]
      },
      {
        "title": "VISUALS",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "CONFIG",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "MISC",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      }

    ],
    "chams" : [
      //NO MORE THAN TWO
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
    ],
    "instructions" : [
      {
        "title": "Step #1",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #2",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #3",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #4",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
    ],
  },
  {
    "name": "Quantum Cheats",
    "logo" : "logo",
    "day": 5,
    "week": 20,
    "month": 40,
    "image": "",
    "game": "Dead By Daylight",
    "featuredVideo": "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr",
    "isUndetected": true,
    "isUpdating": false,
    "genre": {
      "Tags": ["Sponsored", "Undetected", "Tested", "Trending", "Popular"],
      "Providers": "Cheats.pro"
    },
    media: [
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
      { img: "image" },
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
    ],
    "description": "I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop",
    "menu" : [
      // must have at minimum 5 or it breaks, im not fixing
      {img: rust},
      {img: rust},
      {img: rust},
      {img: rust},  
      {img: rust}, 
    ],
    "features": [
      {
          "title": "AIMBOT",
          "items": [
              "Features",
              "Features",
              "Features",
            ]
      },
      {
        "title": "VISUALS",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "CONFIG",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "MISC",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      }

    ],
    "chams" : [
      //NO MORE THAN TWO
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
    ],
    "instructions" : [
      {
        "title": "Step #1",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #2",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #3",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #4",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
    ],
  },
  {
    "name": "Quantum Cheats",
    "logo" : "logo",
    "day": 5,
    "week": 20,
    "month": 40,
    "image": "",
    "game": "Arma 3",
    "featuredVideo": "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr",
    "isUndetected": true,
    "isUpdating": false,
    "genre": {
      "Tags": ["Sponsored", "Undetected", "Tested", "Trending", "Popular"],
      "Providers": "Cheats.pro"
    },
    media: [
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
      { img: "image" },
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
    ],
    "description": "I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop",
    "menu" : [
      // must have at minimum 5 or it breaks, im not fixing
      {img: rust},
      {img: rust},
      {img: rust},
      {img: rust},  
      {img: rust}, 
    ],
    "features": [
      {
          "title": "AIMBOT",
          "items": [
              "Features",
              "Features",
              "Features",
            ]
      },
      {
        "title": "VISUALS",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "CONFIG",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "MISC",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      }

    ],
    "chams" : [
      //NO MORE THAN TWO
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
    ],
    "instructions" : [
      {
        "title": "Step #1",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #2",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #3",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #4",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
    ],
  },
  {
    "name": "Quantum Cheats",
    "logo" : "logo",
    "day": 5,
    "week": 20,
    "month": 40,
    "image": "",
    "game": "Scum",
    "featuredVideo": "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr",
    "isUndetected": true,
    "isUpdating": false,
    "genre": {
      "Tags": ["Sponsored", "Undetected", "Tested", "Trending", "Popular"],
      "Providers": "Cheats.pro"
    },
    media: [
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
      { img: "image" },
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
    ],
    "description": "I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop",
    "menu" : [
      // must have at minimum 5 or it breaks, im not fixing
      {img: rust},
      {img: rust},
      {img: rust},
      {img: rust},  
      {img: rust}, 
    ],
    "features": [
      {
          "title": "AIMBOT",
          "items": [
              "Features",
              "Features",
              "Features",
            ]
      },
      {
        "title": "VISUALS",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "CONFIG",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "MISC",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      }

    ],
    "chams" : [
      //NO MORE THAN TWO
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
    ],
    "instructions" : [
      {
        "title": "Step #1",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #2",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #3",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #4",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
    ],
  },
  {
    "name": "Quantum Cheats",
    "logo" : "logo",
    "day": 5,
    "week": 20,
    "month": 40,
    "image": "",
    "game": "Dayz",
    "featuredVideo": "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr",
    "isUndetected": true,
    "isUpdating": false,
    "genre": {
      "Tags": ["Sponsored", "Undetected", "Tested", "Trending", "Popular"],
      "Providers": "Cheats.pro"
    },
    media: [
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
      { img: "image" },
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
    ],
    "description": "I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop",
    "menu" : [
      // must have at minimum 5 or it breaks, im not fixing
      {img: rust},
      {img: rust},
      {img: rust},
      {img: rust},  
      {img: rust}, 
    ],
    "features": [
      {
          "title": "AIMBOT",
          "items": [
              "Features",
              "Features",
              "Features",
            ]
      },
      {
        "title": "VISUALS",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "CONFIG",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "MISC",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      }

    ],
    "chams" : [
      //NO MORE THAN TWO
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
    ],
    "instructions" : [
      {
        "title": "Step #1",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #2",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #3",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #4",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
    ],
  },
  {
    "name": "Quantum Cheats",
    "logo" : "logo",
    "day": 5,
    "week": 20,
    "month": 40,
    "image": "",
    "game": "Sea of Thieves",
    "featuredVideo": "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr",
    "isUndetected": true,
    "isUpdating": false,
    "genre": {
      "Tags": ["Sponsored", "Undetected", "Tested", "Trending", "Popular"],
      "Providers": "Cheats.pro"
    },
    media: [
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
      { img: "image" },
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
    ],
    "description": "I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop",
    "menu" : [
      // must have at minimum 5 or it breaks, im not fixing
      {img: rust},
      {img: rust},
      {img: rust},
      {img: rust},  
      {img: rust}, 
    ],
    "features": [
      {
          "title": "AIMBOT",
          "items": [
              "Features",
              "Features",
              "Features",
            ]
      },
      {
        "title": "VISUALS",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "CONFIG",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "MISC",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      }

    ],
    "chams" : [
      //NO MORE THAN TWO
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
    ],
    "instructions" : [
      {
        "title": "Step #1",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #2",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #3",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #4",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
    ],
  },
  {
    "name": "Quantum Cheats",
    "logo" : "logo",
    "day": 5,
    "week": 20,
    "month": 40,
    "image": "",
    "game": "War Thunder",
    "featuredVideo": "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr",
    "isUndetected": true,
    "isUpdating": false,
    "genre": {
      "Tags": ["Sponsored", "Undetected", "Tested", "Trending", "Popular"],
      "Providers": "Cheats.pro"
    },
    media: [
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
      { img: "image" },
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
    ],
    "description": "I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop",
    "menu" : [
      // must have at minimum 5 or it breaks, im not fixing
      {img: rust},
      {img: rust},
      {img: rust},
      {img: rust},  
      {img: rust}, 
    ],
    "features": [
      {
          "title": "AIMBOT",
          "items": [
              "Features",
              "Features",
              "Features",
            ]
      },
      {
        "title": "VISUALS",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "CONFIG",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "MISC",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      }

    ],
    "chams" : [
      //NO MORE THAN TWO
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
    ],
    "instructions" : [
      {
        "title": "Step #1",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #2",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #3",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #4",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
    ],
  },
  {
    "name": "Quantum Cheats",
    "logo" : "logo",
    "day": 5,
    "week": 20,
    "month": 40,
    "image": "",
    "game": "Fortnite",
    "featuredVideo": "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr",
    "isUndetected": true,
    "isUpdating": false,
    "genre": {
      "Tags": ["Sponsored", "Undetected", "Tested", "Trending", "Popular"],
      "Providers": "Cheats.pro"
    },
    media: [
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
      { img: "image" },
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
    ],
    "description": "I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop",
    "menu" : [
      // must have at minimum 5 or it breaks, im not fixing
      {img: rust},
      {img: rust},
      {img: rust},
      {img: rust},  
      {img: rust}, 
    ],
    "features": [
      {
          "title": "AIMBOT",
          "items": [
              "Features",
              "Features",
              "Features",
            ]
      },
      {
        "title": "VISUALS",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "CONFIG",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "MISC",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      }

    ],
    "chams" : [
      //NO MORE THAN TWO
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
    ],
    "instructions" : [
      {
        "title": "Step #1",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #2",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #3",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #4",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
    ],
  },
  {
    "name": "Quantum Cheats",
    "logo" : "logo",
    "day": 5,
    "week": 20,
    "month": 40,
    "image": "",
    "game": "7 Days To Die",
    "featuredVideo": "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr",
    "isUndetected": true,
    "isUpdating": false,
    "genre": {
      "Tags": ["Sponsored", "Undetected", "Tested", "Trending", "Popular"],
      "Providers": "Cheats.pro"
    },
    media: [
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
      { img: "image" },
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
    ],
    "description": "I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop",
    "menu" : [
      // must have at minimum 5 or it breaks, im not fixing
      {img: rust},
      {img: rust},
      {img: rust},
      {img: rust},  
      {img: rust}, 
    ],
    "features": [
      {
          "title": "AIMBOT",
          "items": [
              "Features",
              "Features",
              "Features",
            ]
      },
      {
        "title": "VISUALS",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "CONFIG",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "MISC",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      }

    ],
    "chams" : [
      //NO MORE THAN TWO
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
    ],
    "instructions" : [
      {
        "title": "Step #1",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #2",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #3",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #4",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
    ],
  },
  {
    "name": "Quantum Cheats",
    "logo" : "logo",
    "day": 5,
    "week": 20,
    "month": 40,
    "image": "",
    "game": "The Finals",
    "featuredVideo": "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr",
    "isUndetected": true,
    "isUpdating": false,
    "genre": {
      "Tags": ["Sponsored", "Undetected", "Tested", "Trending", "Popular"],
      "Providers": "Cheats.pro"
    },
    media: [
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
      { img: "image" },
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
    ],
    "description": "I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop",
    "menu" : [
      // must have at minimum 5 or it breaks, im not fixing
      {img: rust},
      {img: rust},
      {img: rust},
      {img: rust},  
      {img: rust}, 
    ],
    "features": [
      {
          "title": "AIMBOT",
          "items": [
              "Features",
              "Features",
              "Features",
            ]
      },
      {
        "title": "VISUALS",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "CONFIG",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "MISC",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      }

    ],
    "chams" : [
      //NO MORE THAN TWO
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
    ],
    "instructions" : [
      {
        "title": "Step #1",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #2",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #3",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #4",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
    ],
  },
  {
    "name": "Quantum Cheats",
    "logo" : "logo",
    "day": 5,
    "week": 20,
    "month": 40,
    "image": "",
    "game": "Counter Strike 2",
    "featuredVideo": "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr",
    "isUndetected": true,
    "isUpdating": false,
    "genre": {
      "Tags": ["Sponsored", "Undetected", "Tested", "Trending", "Popular"],
      "Providers": "Cheats.pro"
    },
    media: [
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
      { img: "image" },
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
    ],
    "description": "I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop",
    "menu" : [
      // must have at minimum 5 or it breaks, im not fixing
      {img: rust},
      {img: rust},
      {img: rust},
      {img: rust},  
      {img: rust}, 
    ],
    "features": [
      {
          "title": "AIMBOT",
          "items": [
              "Features",
              "Features",
              "Features",
            ]
      },
      {
        "title": "VISUALS",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "CONFIG",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "MISC",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      }

    ],
    "chams" : [
      //NO MORE THAN TWO
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
    ],
    "instructions" : [
      {
        "title": "Step #1",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #2",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #3",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #4",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
    ],
  },
  {
    "name": "Quantum Cheats",
    "logo" : "logo",
    "day": 5,
    "week": 20,
    "month": 40,
    "image": "",
    "game": "Ark: Survival Ascended",
    "featuredVideo": "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr",
    "isUndetected": true,
    "isUpdating": false,
    "genre": {
      "Tags": ["Sponsored", "Undetected", "Tested", "Trending", "Popular"],
      "Providers": "Cheats.pro"
    },
    media: [
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
      { img: "image" },
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
    ],
    "description": "I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop",
    "menu" : [
      // must have at minimum 5 or it breaks, im not fixing
      {img: rust},
      {img: rust},
      {img: rust},
      {img: rust},  
      {img: rust}, 
    ],
    "features": [
      {
          "title": "AIMBOT",
          "items": [
              "Features",
              "Features",
              "Features",
            ]
      },
      {
        "title": "VISUALS",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "CONFIG",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "MISC",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      }

    ],
    "chams" : [
      //NO MORE THAN TWO
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
    ],
    "instructions" : [
      {
        "title": "Step #1",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #2",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #3",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #4",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
    ],
  },
  {
    "name": "Quantum Cheats",
    "logo" : "logo",
    "day": 5,
    "week": 20,
    "month": 40,
    "image": "",
    "game": "Other",
    "featuredVideo": "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr",
    "isUndetected": true,
    "isUpdating": false,
    "genre": {
      "Tags": ["Sponsored", "Undetected", "Tested", "Trending", "Popular"],
      "Providers": "Cheats.pro"
    },
    media: [
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
      { img: "image" },
      { video: "https://www.youtube.com/embed/M_2EbbK6-YA?si=vA5NwRaWHo09mUOr" },
    ],
    "description": "I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poop I love poop I love poop I love poop I love poop I love poop I love poop I love poopI love poopI love poop",
    "menu" : [
      // must have at minimum 5 or it breaks, im not fixing
      {img: rust},
      {img: rust},
      {img: rust},
      {img: rust},  
      {img: rust}, 
    ],
    "features": [
      {
          "title": "AIMBOT",
          "items": [
              "Features",
              "Features",
              "Features",
            ]
      },
      {
        "title": "VISUALS",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "CONFIG",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      },
      {
        "title": "MISC",
        "items": [
            "Features",
            "Features",
            "Features",
          ]
      }

    ],
    "chams" : [
      //NO MORE THAN TWO
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
      {
        "img": rust,
        "title": "AIM VISUALS",
        "desc": "Quantum Cheats visuals for aim include a crosshair, fov circle target snapline, aim marker, and projectile simulator",
      },
    ],
    "instructions" : [
      {
        "title": "Step #1",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #2",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #3",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
      {
        "title": "Step #4",
        "text": "So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly, then refund it because you realize it sucks ass. So you want to launch the cheat, make sure it runs correctly.",
      },
    ],
  },

  
]


const uniqueGamesSet = new Set(cheats.map(cheat => cheat.game));

// Convert Set back to an array
export const gamesdata = Array.from(uniqueGamesSet);


const uniqueProviderSet = new Set(cheats.map(cheat => cheat.name));

// Convert Set back to an array
export const providerdata = Array.from(uniqueProviderSet);