import './App.css';
import { BrowserRouter as Router, Route, Routes, Switch } from 'react-router-dom';
import {Navbar} from './components/navbar.js'
import {Footer} from './components/footer.js'

import ParticlesComponent from './components/particles.js';

import AnimatedRoutes from './components/AnimatedRoutes.js';

export const App = () => {
  return (
    <div className="App">
      <Router>
        <Navbar />
          <ParticlesComponent />
          <AnimatedRoutes />
        <Footer />
        </Router>
    </div>
  );
}

export default App;
