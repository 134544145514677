
export const gamescategory = [
  {
    "category": "Popular",
    "games": [
      "Rust",
      "Apex Legends",
      "Escape from Tarkov",
      "Dead By Daylight",
      "Arma 3",
      "Dayz",
      "Counter Strike 2",
      "Fortnite",
      "Rainbow Six Siege",
    ]
  },
  {
    "category": "New",
    "games": [
      "The Finals",
      "Palworld",
    ]
  },
  {
    "category": "Other",
    "games": [
      "Stalker Online",
      "Sea of Thieves",
      "Battlefield V",
      "War Thunder",
      "Battlefield 4",
      "Anomaly Zone",
      "Left 4 Dead 2",
      "Battlefield 1",
      "Dayz",
      "Hell Let Loose",
      "Lost Light",
      "Battlebit",
      "Texas Chainsaw Massacre",
      "Dark and Darker",
      "The Front",
      "Battlefield 3",
      "Scum",
      "Paladins",
      "Battlefield Hardline",
      "Titanfall 2",
      "Team Fortress 2",
      "Deceit",
      "Will to Live Online",
      "PUBG Mobile",
      "Caliber",
      "Marauders",
      "Rogue Company",
      "Shatterline",
      "Star Wars: Battlefront 2",
      "Black Desert Online",
      "7 Days to Die",
      "Unturned",
      "Farlight",
      "The Day Before",
      "PUBG"
    ]
    
  }
]
